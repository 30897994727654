import React from "react";
import { Button, Card, Descriptions, List, Popover, Radio } from "antd";
import currencyFormatter from "currency-formatter";
import PropTypes from "prop-types";
import { DeleteOutlined } from "@ant-design/icons";

const PhotoPackagesCard = ({
  packages,
  selectedPhotoProducts,
  productsInShoppingCart,
  onSelect,
  onDeselect,
  onDeleteFromCart,
}) => {
  function productIsSelected(productId) {
    return selectedPhotoProducts.find((p) => p.id === productId) != null;
  }

  function selectedPhotoPackage() {
    const selectedPackage = selectedPhotoProducts.find(
      (sp) => Number(sp.is_single_photo) === 0
    );

    return selectedPackage ? selectedPackage.id : null;
  }

  function photoPackageIsInShoppingCart() {
    if (!productsInShoppingCart) return null;
    const photoPackage = productsInShoppingCart.find(
      (p) => p.is_single_photo === 0
    );

    if (photoPackage) return photoPackage.package_name;

    return null;
  }

  function handleDeletePackageClick() {
    const photoPackage = productsInShoppingCart.find(
      (p) => p.is_single_photo === 0
    );

    if (photoPackage) {
      photoPackage.removeFromCart();
      onDeleteFromCart();
    }
  }

  return (
    <>
      <Card className="photo-packages-card" title="Foto-Pakete">
        {photoPackageIsInShoppingCart() !== null ? (
          <>
            <h4 style={{ color: "#555555" }}>Im Warenkorb</h4>
            <h2>{photoPackageIsInShoppingCart()}</h2>
            <Button
              type="link"
              icon={<DeleteOutlined />}
              style={{ margin: 0, padding: 0 }}
              onClick={handleDeletePackageClick}
            >
              Aus Warenkorb entfernen
            </Button>
          </>
        ) : (
          <Radio.Group
            value={selectedPhotoPackage()}
            onChange={(e) => {
              onSelect(e.target.value);
            }}
          >
            <Descriptions
              column={1}
              labelStyle={{ width: "80%" }}
              contentStyle={{ justifyContent: "flex-end", fontWeight: "bold" }}
              colon={false}
            >
              {packages.map((p) => (
                <Descriptions.Item
                  key={p.id}
                  label={
                    <Popover
                      content={
                        <List style={{ maxWidth: "200px" }}>
                          {p.descriptions.map((pd) => (
                            <List.Item key={pd.id}>
                              {pd.product_description}
                            </List.Item>
                          ))}
                        </List>
                      }
                      placement="left"
                    >
                      <Radio
                        value={p.id}
                        onClick={() => {
                          if (productIsSelected(p.id)) {
                            onDeselect(p.id);
                          }
                        }}
                      >
                        {p.package_name}
                      </Radio>
                    </Popover>
                  }
                >
                  {currencyFormatter.format(Number(p.price) / 100, {
                    code: "EUR",
                  })}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Radio.Group>
        )}
      </Card>
    </>
  );
};

PhotoPackagesCard.propTypes = {
  children: PropTypes.any,
  packages: PropTypes.array,
  selectedPhotoProducts: PropTypes.array,
  productsInShoppingCart: PropTypes.array,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  onDeleteFromCart: PropTypes.func,
};

export default PhotoPackagesCard;
