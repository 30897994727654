import { Card, Col, Descriptions, Divider, Row, Spin } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import StartnummernCard from "./StartnummernCard";
import FotosVonStartnummer from "./FotosVonStartnummer";
import PhotoProductCard from "./PhotoProductCard";
import { format } from "currency-formatter";

const EventStartnummernSucheUndFotos = () => {
  const {
    eventUrlKeywords,
    subEventUrlKeywords,
    startNo: urlStartNo,
    photoId,
  } = useParams();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);
  const [photoProducts, setPhotoProducts] = useState([]);
  const [subEvents, setSubEvents] = useState(null);
  const [subEvent, setSubEvent] = useState(null);
  const [currentStartNo, setCurrentStartNo] = useState(null);

  const navigate = useNavigate();

  function loadEventInfos() {
    axios
      .get(`https://api.picthis.one/events/${eventUrlKeywords}`)
      .then(({ data }) => {
        if (data && data.event) {
          const { event, subevents } = data;
          setEvent(event);
          setLoading(false);

          if (subevents) {
            setSubEvent(
              subevents.find((se) => se.url_keywords === subEventUrlKeywords)
            );
            setSubEvents(subevents);
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  }

  function loadPhotoProductsForEvent() {
    if (subEvent) {
      setLoading(true);
      axios
        .get(`https://api.picthis.one/products/event/${subEvent.id}`)
        .then(({ data }) => {
          if (data && data.photoProducts) {
            setPhotoProducts(data.photoProducts);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }

  useEffect(loadEventInfos, [eventUrlKeywords, subEventUrlKeywords]);
  useEffect(loadPhotoProductsForEvent, [subEvent]);

  useEffect(() => {
    setCurrentStartNo(urlStartNo);
  }, [urlStartNo]);

  return (
    <>
      {event && (
        <div className="event-image-container small">
          <div className="event-image">
            <img src={event.event_cover_image_url} alt={event.event_name} />
          </div>
          <div className="event-image-overlay">
            <div className="event-details">
              <div className="event-title">{event.event_name}</div>
              {subEvents &&
                subEvents.map((se) => (
                  <Link
                    key={se.url_keywords}
                    to={`/event/${event.url_keywords}/${se.url_keywords}${
                      currentStartNo ? `/${currentStartNo}` : ""
                    }`}
                    className={`subevent-button ${
                      se.url_keywords !== subEvent.url_keywords
                        ? "inactive"
                        : ""
                    }`}
                  >
                    <div>
                      <span>
                        <b>{se.event_name}</b>
                      </span>
                      <span>
                        {dayjs(se.event_start_date).format("DD.MM.YYYY")}
                      </span>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="content event text-left">
          {event && (
            <>
              <div className="page-header">
                <div className="event-info">
                  <img
                    src={event.event_logo_url}
                    alt={`Logo ${event.event_name}`}
                    className="event-logo"
                  />
                  <div className="event-location">
                    {getUnicodeFlagIcon(event.event_country)}{" "}
                    {event.event_location}
                  </div>
                  <div className="event-website">
                    <a
                      href={
                        event.event_website.indexOf("http") === 0
                          ? event.event_website
                          : `https://${event.event_website}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {event.event_website}
                    </a>
                  </div>
                </div>
                <Spin spinning={loading}>
                  <StartnummernCard
                    defaultStartNo={urlStartNo}
                    onChange={(startNo) => {
                      setCurrentStartNo(startNo);
                      navigate(
                        `/event/${eventUrlKeywords}/${subEventUrlKeywords}/${startNo}`
                      );
                    }}
                  />
                </Spin>
              </div>
              <Divider type="horizontal" />
              {event && !currentStartNo && (
                <p
                  style={{
                    color: "#999999",
                    fontSize: "1.3rem",
                    margin: "48px 0",
                  }}
                >
                  Gib eine Startnummer ein, um Fotos ansehen zu können.
                </p>
              )}
              {event && currentStartNo && (
                <FotosVonStartnummer
                  eventUrlKeywords={eventUrlKeywords}
                  subEventUrlKeywords={subEventUrlKeywords}
                  event={event}
                  subEvent={subEvent}
                  startNo={currentStartNo}
                  photoId={photoId}
                  photoProducts={photoProducts}
                />
              )}
              <Divider type="horizontal" />
              {/** Angebote hier */}
              <Row gutter={[24, 24]}>
                {photoProducts
                  .filter((pp) => pp.is_single_photo === 0)
                  .map((pp) => (
                    <Col key={pp.id} xs={24} md={12} lg={8}>
                      <PhotoProductCard photoProduct={pp} />
                    </Col>
                  ))}
                <Col xs={24} md={12} lg={8}>
                  <Card className="photo-product-card" title="Einzelfotos">
                    <Descriptions
                      labelStyle={{ width: "70%" }}
                      column={1}
                      colon=""
                      contentStyle={{ justifyContent: "flex-end" }}
                    >
                      {photoProducts &&
                        photoProducts
                          .filter((pp) => pp.is_single_photo === 1)
                          .map((pp) => (
                            <Descriptions.Item
                              key={pp.id}
                              label={pp.package_name}
                            >
                              {format(Number(pp.price) / 100, { code: "EUR" })}
                            </Descriptions.Item>
                          ))}
                    </Descriptions>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EventStartnummernSucheUndFotos;
