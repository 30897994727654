import React, { useRef, useState } from "react";
import { Alert, Button, Col, Form, Input, Result, Row, Spin } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import MailSentAnimation from "../PicthisAnimation/MailSentAnimation";
import { useCookies } from "react-cookie";
import LockAnimation from "../PicthisAnimation/LockAnimation";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const captchaRef = useRef(null);

  const [cookies] = useCookies();

  async function handleSendMail() {
    setLoading(true);
    const token = await captchaRef.current.executeAsync();

    if (token) {
      axios
        .post("https://api.picthis.one/mail/contact", {
          name,
          email,
          message,
          googleToken: token,
        })
        .then(({ data }) => {
          if (data && data.success) {
            setLoading(false);
            setSuccess(true);
            setError(null);
          } else {
            setLoading(false);
            setError(
              data.error ||
                "Die Nachricht konnte leider nicht versendet werden. Bitte versuche es später noch einmal."
            );
          }
        })
        .catch(() => {
          setLoading(false);
          setError(
            "Die Nachricht konnte leider nicht versendet werden. Bitte versuche es später noch einmal."
          );
        });
    }
  }

  return (
    <div className="container">
      <div className="content" style={{ width: "100%" }}>
        <h1 className="big-heading">Kontakt</h1>
        {!cookies.accepted && (
          <Result
            icon={<LockAnimation style={{ height: "200px" }} />}
            subTitle={
              <>
                Um das Kontaktformular nutzen zu können, stimme bitte zuerst
                unserer Datenschutzerklärung zu.
              </>
            }
          />
        )}
        {cookies.accepted && (
          <>
            {success && (
              <Result
                icon={<MailSentAnimation style={{ width: "180px" }} />}
                title="Vielen Dank für deine Anfrage!"
                subTitle="Wir melden uns schnellstmöglich bei dir."
              />
            )}
            {error && (
              <Alert
                type="error"
                showIcon
                message={error}
                style={{ marginBottom: "24px" }}
              />
            )}
            {!success && (
              <Row gutter={24} style={{ width: "100%" }}>
                <Col xs={24} lg={{ span: 16, offset: 4 }}>
                  <Spin spinning={loading}>
                    <Form
                      labelCol={{ xs: { span: 24 } }}
                      wrapperCol={{ xs: { span: 24 } }}
                      labelAlign="left"
                      style={{ width: "100%" }}
                    >
                      <Form.Item label="Name">
                        <Input
                          placeholder="Vorname Nachname"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label="E-Mail">
                        <Input
                          placeholder="E-Mail Adresse"
                          type="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label="Nachricht">
                        <Input.TextArea
                          rows={5}
                          placeholder="Deine Nachricht an uns"
                          style={{ resize: "none" }}
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Form>
                  </Spin>
                  <Button
                    type="primary"
                    onClick={handleSendMail}
                    loading={loading}
                    disabled={!name || !email || !message}
                  >
                    Absenden
                  </Button>
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey="6Lf-B2sdAAAAABRbLFMNNVRUtPhHJ1A0_uuzeFiA"
                    size="invisible"
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Contact;
