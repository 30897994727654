import React, { useState, useEffect } from "react";
import { Button, Col, List, Row, Space } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import shoppingCart from "../../shoppingCart/shoppingCart.ts";
import currencyFormatter from "currency-formatter";
import axios from "axios";

export default function ShoppingCart() {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(shoppingCart.getInstance().getItems());

  function handleInitPayment() {
    setLoading(true);
    const windowReference = window.open();

    axios
      .post(`https://api.picthis.one/payment/create`, { shoppingCart: items })
      .then(({ data }) => {
        const { success, error, redirectTo } = data;

        setLoading(false);

        if (success && redirectTo) {
          windowReference.location = redirectTo;
        } else {
          windowReference.close();
          message.error(error || "Fehler.");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(
    () =>
      shoppingCart.getInstance().subscribeToUpdates(() => {
        setItems(shoppingCart.getInstance().getItems());
      }),
    []
  );

  const totalPrice = items
    ? items.reduce((sum, curr) => Number(curr.price) + sum, 0)
    : 0;

  return (
    <div style={{ width: "460px", maxWidth: "90vw", padding: "24px" }}>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <Row style={{ justifyContent: "space-between" }}>
          <h2 style={{ margin: 0, padding: 0 }}>Warenkorb</h2>
          <Button
            type="link"
            onClick={() => {
              items.forEach((i) => {
                shoppingCart.getInstance().removePhotoProductFromCart(i);
              });
            }}
            disabled={items.length === 0}
          >
            leeren
          </Button>
        </Row>
        <List
          dataSource={items}
          renderItem={(item) => (
            <List.Item key={`${item.startNo}-${item.photoProductId}`}>
              <Row style={{ width: "100%" }}>
                <Col
                  xs={22}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "6px",
                  }}
                >
                  <Button
                    icon={<DeleteFilled />}
                    type="link"
                    onClick={() => {
                      shoppingCart
                        .getInstance()
                        .removePhotoProductFromCart(item);
                    }}
                  />
                  <Space direction="vertical">
                    <span style={{ fontSize: "0.8rem", color: "#aaaaaa" }}>
                      {item.eventName}
                    </span>
                    <span style={{ color: "#333333" }}>
                      {item.packageName} - Startnummer {item.startNo}
                    </span>
                  </Space>
                </Col>
                <Col
                  xs={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <span style={{ fontSize: "1rem" }}>
                    <b>
                      {currencyFormatter.format(Number(item.price) / 100, {
                        code: "EUR",
                      })}
                    </b>
                  </span>
                </Col>
              </Row>
            </List.Item>
          )}
          locale={{ emptyText: "Noch nichts in deinem Warenkorb." }}
        />
        {items && items.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                columnGap: "12px",
                alignItems: "baseline",
              }}
            >
              <span style={{ fontSize: "1.1rem", color: "#666666" }}>
                Summe
              </span>
              <span style={{ fontSize: "2rem" }}>
                <b>
                  {currencyFormatter.format(Number(totalPrice) / 100, {
                    code: "EUR",
                  })}
                </b>
              </span>
            </div>
            <Button
              type="primary"
              block
              loading={loading}
              onClick={handleInitPayment}
            >
              Jetzt bezahlen
            </Button>
          </>
        )}
      </Space>
    </div>
  );
}
