import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import PropTypes from "prop-types";

const PicthisAnimation = ({ animationData, loop, style }) => {
  return (
    <Player
      src={animationData}
      autoplay
      loop={loop || false}
      style={style}
      keepLastFrame
    />
  );
};

PicthisAnimation.propTypes = {
  children: PropTypes.any,
  animationData: PropTypes.object.isRequired,
  loop: PropTypes.bool,
  style: PropTypes.object,
};

export default PicthisAnimation;
