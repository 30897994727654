import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, message, Modal, Row, Space, Spin } from "antd";
import ShoppingCart from "../../shoppingCart/shoppingCart.ts";

import "./styles.less";
import PhotoPackagesCard from "./PhotoPackagesCard";
import SinglePhotosCard from "./SinglePhotosCard";
import {
  BackwardOutlined,
  ForwardOutlined,
  ShoppingFilled,
} from "@ant-design/icons";

const ParticipantPhotoModal = ({
  visible,
  photoId,
  participant,
  onClose,
  photoProducts,
  event,
  subEvent,
  hasPrevPhoto,
  hasNextPhoto,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedPhotoProducts, setSelectedPhotoProducts] = useState([]);
  const [productsInShoppingCart, setProductsInShoppingCart] = useState([]);

  function onSelectPhotoProduct(productId) {
    const alreadySelected =
      selectedPhotoProducts.find((sp) => sp.id === productId) != null;

    const productToAdd = photoProducts.find((p) => p.id === productId);

    /* 
    if (Number(productToAdd.is_single_photo) === 0) {
      // checken, ob ein einzelfoto bereits ausgewählt würde
      if (selectedPhotoProducts.filter(s => Number(s.is_single_photo) === 1).length > 0) {
        Modal.confirm({title: "Hinweis", content: "Du hast bereits Einzelfotos ausgewählt, die"})
      }
    }
    */

    if (!alreadySelected) {
      if (productToAdd) {
        // wenn es ein package ist, das andere package entfernen
        if (Number(productToAdd.is_single_photo) === 0) {
          setSelectedPhotoProducts([
            ...selectedPhotoProducts.filter(
              (sp) => Number(sp.is_single_photo) === 1
            ),
            productToAdd,
          ]);
        } else {
          setSelectedPhotoProducts([...selectedPhotoProducts, productToAdd]);
        }
      }
    }
  }

  function updateCartItems() {
    if (participant) {
      setProductsInShoppingCart(
        photoProducts
          .filter((p) =>
            ShoppingCart.getInstance().productIsInCart({
              isSinglePhoto: Number(p.is_single_photo) === 1,
              photoProductId: p.id,
              startNo: participant.start_no,
              subEventId: subEvent.id,
              photoId: Number(p.is_single_photo) === 1 ? photoId : null,
            })
          )
          .map((p) => ({
            ...p,
            removeFromCart: () =>
              ShoppingCart.getInstance().removePhotoProductFromCart({
                isSinglePhoto: Number(p.is_single_photo) === 1,
                photoProductId: p.id,
                startNo: participant.start_no,
                subEventId: subEvent.id,
                photoId: Number(p.is_single_photo) === 1 ? photoId : null,
              }),
          }))
      );
    }
  }

  function onDeselectPhotoProduct(productId) {
    const alreadySelected = selectedPhotoProducts.find(
      (sp) => sp.id === productId
    );

    if (alreadySelected) {
      setSelectedPhotoProducts(
        selectedPhotoProducts.filter((sp) => sp.id !== productId)
      );
    }
  }

  function handleAddToCart() {
    if (selectedPhotoProducts.length > 0) {
      selectedPhotoProducts.forEach((s) => {
        const product = {
          isSinglePhoto: Number(s.is_single_photo) === 1,
          photoProductId: s.id,
          startNo: participant.start_no,
          subEventId: subEvent.id,
          photoId: Number(s.is_single_photo) === 1 ? photoId : null,
          eventName: subEvent.event_name,
          packageName: s.package_name,
          price: s.price,
        };
        ShoppingCart.getInstance().addPhotoProductToCart(product);
      });

      updateCartItems();

      message.success("Auswahl hinzugefügt!");
    } else {
      message.error("Kein Produkt ausgewählt.");
    }
  }

  useEffect(() => {
    setSelectedPhotoProducts([]);
    setProductsInShoppingCart([]);
    updateCartItems();
  }, [photoProducts, participant, photoId, visible]);

  console.log("hasNextPhoto", hasNextPhoto);

  return (
    <Modal
      maskClosable={true}
      maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      footer={null}
      visible={visible}
      onCancel={onClose}
      width="90%"
      style={{ maxWidth: "900px" }}
      className="participant-photo-modal"
    >
      {participant && (
        <>
          <Row justify="center" align="center">
            <h1 style={{ margin: 0, padding: 0 }}>
              {participant
                ? `${participant.first_name} ${participant.last_name}`
                : ""}
            </h1>
          </Row>
          <Row justify="center" align="center">
            <h3 style={{ fontWeight: 200 }}>
              Gesamtzeit {participant && participant.run_time}
            </h3>
          </Row>
          <Row gutter={24} style={{ margin: "48px 0" }}>
            <Col xs={24} md={12} className="photo-column">
              <Spin spinning={loading} style={{ width: "100%" }}>
                <div className="image-wrapper">
                  <img
                    src={`https://api.picthis.one/photos/thumbnail/${photoId}/`}
                    alt={`Foto von ${participant.first_name} ${participant.last_name}`}
                    className="participant-photo"
                    onLoad={() => {
                      setLoading(false);
                    }}
                  />
                </div>
              </Spin>
              <Space direction="horizontal" style={{ marginTop: "24px" }}>
                <Button
                  icon={<BackwardOutlined />}
                  shape="circle"
                  onClick={hasPrevPhoto}
                  disabled={hasPrevPhoto == null}
                />
                <Button
                  icon={<ForwardOutlined />}
                  shape="circle"
                  onClick={hasNextPhoto}
                  disabled={hasNextPhoto == null}
                />
              </Space>
            </Col>
            <Col xs={24} md={12}>
              {event && Number(event.ready_for_selling) === 1 ? (
                <>
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size="large"
                  >
                    <PhotoPackagesCard
                      packages={
                        photoProducts
                          ? photoProducts.filter(
                              (pp) => Number(pp.is_single_photo) === 0
                            )
                          : []
                      }
                      selectedPhotoProducts={selectedPhotoProducts}
                      productsInShoppingCart={productsInShoppingCart}
                      onSelect={onSelectPhotoProduct}
                      onDeselect={onDeselectPhotoProduct}
                      onDeleteFromCart={updateCartItems}
                    />
                    <SinglePhotosCard
                      participant={participant}
                      photoId={photoId}
                      products={
                        photoProducts
                          ? photoProducts.filter(
                              (pp) => Number(pp.is_single_photo) === 1
                            )
                          : []
                      }
                      selectedPhotoProducts={selectedPhotoProducts}
                      productsInShoppingCart={productsInShoppingCart}
                      onSelect={onSelectPhotoProduct}
                      onDeselect={onDeselectPhotoProduct}
                      onDeleteFromCart={updateCartItems}
                    />

                    <Button
                      type="primary"
                      icon={<ShoppingFilled />}
                      block
                      className="add-to-cart-button"
                      onClick={handleAddToCart}
                    >
                      Auswahl in den Warenkorb geben
                    </Button>
                  </Space>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <h3 style={{ color: "#999999" }}>
                    Die Fotos sind noch nicht bereit für den Verkauf.
                  </h3>
                </div>
              )}
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

ParticipantPhotoModal.propTypes = {
  children: PropTypes.any,
  visible: PropTypes.bool.isRequired,
  photoId: PropTypes.string,
  participant: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  photoProducts: PropTypes.array,
  event: PropTypes.object.isRequired,
  subEvent: PropTypes.object.isRequired,
  hasPrevPhoto: PropTypes.func,
  hasNextPhoto: PropTypes.func,
};

export default ParticipantPhotoModal;
