import React from "react";
import { Link } from "react-router-dom";
import { Card } from "antd";
import dayjs from "dayjs";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import PropTypes from "prop-types";

const EventMitSubevents = ({ event, subevents }) => {
  return (
    <>
      <div className="event-image-container">
        <div className="event-image">
          <img src={event.event_cover_image_url} alt={event.event_name} />
        </div>
        <div className="event-image-overlay">
          <div className="event-details">
            <div className="event-title">{event.event_name}</div>
            {subevents &&
              subevents.map((se) => (
                <Link
                  key={se.url_keywords}
                  to={`/event/${event.url_keywords}/${se.url_keywords}`}
                  className="subevent-button"
                >
                  <div>
                    <span>
                      <b>{se.event_name}</b>
                    </span>
                    <span>
                      {dayjs(se.event_start_date).format("DD.MM.YYYY")}
                    </span>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="content event text-left">
          {event && (
            <>
              <div className="page-header">
                <div className="event-info">
                  <img
                    src={event.event_logo_url}
                    alt={`Logo ${event.event_name}`}
                    className="event-logo"
                  />
                  <div className="event-location">
                    {getUnicodeFlagIcon(event.event_country)}{" "}
                    {event.event_location}
                  </div>
                  <div className="event-website">
                    <a
                      href={
                        event.event_website.indexOf("http") === 0
                          ? event.event_website
                          : `https://${event.event_website}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {event.event_website}
                    </a>
                  </div>
                </div>
                <div>
                  {subevents &&
                    subevents.map((se) => (
                      <Link
                        key={se.url_keywords}
                        to={`/event/${event.url_keywords}/${se.url_keywords}`}
                        className="subevent-button"
                      >
                        <Card
                          bodyStyle={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{ fontSize: "1rem", fontWeight: "bold" }}
                          >
                            {se.event_name}
                          </span>
                          <span>
                            {dayjs(se.event_start_date).format("DD.MM.YYYY")}
                          </span>
                        </Card>
                      </Link>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

EventMitSubevents.propTypes = {
  children: PropTypes.any,
  event: PropTypes.object,
  subevents: PropTypes.array,
};

export default EventMitSubevents;
