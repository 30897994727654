import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./styles.less";

export default function CookieBanner() {
  const setCookie = useCookies(["accepted"])[1];

  function handleAcceptCookies() {
    setCookie("accepted", "1", {});
  }

  return (
    <div className="cookie-banner">
      <p>
        Unsere Webseite verwendet Cookies. Um alle Funktionen nutzen zu können
        stimme bitte unserer{" "}
        <Link to="/privacy" target="_blank">
          Datenschutzerklärung
        </Link>{" "}
        zu.
      </p>
      <Button type="default" onClick={handleAcceptCookies}>
        Akzeptieren
      </Button>
    </div>
  );
}
