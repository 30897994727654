import React, { useEffect, useState } from "react";
import Navigation from "./components/Navigation";
import { Route, Routes, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import CacheBuster from "./components/CacheBuster";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutUs";
import Home from "./components/Home";
import Event from "./components/Event";

import EventStartnummernSucheUndFotos from "./components/Event/EventStartnummernSucheUndFotos";
import PaymentSuccess from "./components/Payment/PaymentSuccess";
import Contact from "./components/Contact";
import CookieBanner from "./components/CookieBanner";
import FAQ from "./components/FAQ";
import Imprint from "./components/Imprint";
import Privacy from "./components/Privacy";

import "./App.less";
import "./styles/fonts.less";
import "./styles/desktop.less";
import "./styles/tablet.less";
import "./styles/mobile.less";
import ShoppingCartFullpage from "./components/ShoppingCart/ShoppingCartFullpage";

function App() {
  const [offset, setOffset] = useState(0);
  const [cookies] = useCookies();

  const location = useLocation();

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <>
            <Navigation
              dark={
                Number(offset) > 150 ||
                [
                  "/about-us",
                  "/payment/success",
                  "/contact",
                  "/faq",
                  "/imprint",
                  "/privacy",
                  "/shopping-cart",
                ].indexOf(location.pathname) >= 0
                  ? true
                  : false
              }
            />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/shopping-cart" element={<ShoppingCartFullpage />} />
              <Route path="/event/:eventUrlKeywords" element={<Event />} />
              <Route
                path="/event/:eventUrlKeywords/:subEventUrlKeywords/:startNo/:photoId"
                element={<EventStartnummernSucheUndFotos />}
              />
              <Route
                path="/event/:eventUrlKeywords/:subEventUrlKeywords/:startNo"
                element={<EventStartnummernSucheUndFotos />}
              />
              <Route
                path="/event/:eventUrlKeywords/:subEventUrlKeywords"
                element={<EventStartnummernSucheUndFotos />}
              />
              <Route path="/payment/success" element={<PaymentSuccess />} />
            </Routes>
            <Footer />
            {!cookies.accepted && <CookieBanner />}
          </>
        );
      }}
    </CacheBuster>
  );
}

export default App;
