import React, { useEffect, useState } from "react";
import { Button, Card, Input, Row, Select, Spin } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import HeaderSlider from "../HeaderSlider";
import "./styles.less";
import EventCard from "./EventCard";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [subEvents, setSubEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startNo, setStartNo] = useState(null);

  const navigate = useNavigate();

  const loadEvents = () => {
    setLoading(true);
    setEvents([]);

    axios
      .get("https://api.picthis.one/events/active")
      .then(({ data }) => {
        if (data && data.events) {
          const { events } = data;
          setEvents(events);

          setSubEvents(
            events.reduce((subs, e) => {
              if (e.subevents) {
                return [...subs, ...e.subevents];
              }
              return subs;
            }, [])
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  function handleGotoEvent() {
    const parentEventId = selectedEvent.parent_event_id;
    const parentEvent = events.find((e) => e.id === parentEventId);

    if (parentEvent) {
      navigate(
        `/event/${parentEvent.url_keywords}/${selectedEvent.url_keywords}/${startNo}`
      );
    }
  }

  useEffect(loadEvents, []);

  return (
    <>
      <HeaderSlider events={events.slice(0, 4)} />
      <Spin spinning={loading} size="large">
        <div className="container">
          <div className="content">
            <h1 className="big-heading">Alle Events</h1>
            <Card className="startnummern-suche-card">
              <Row>
                <Select
                  loading={loading}
                  options={subEvents.map((e) => {
                    const event = events.find(
                      (e2) => e2.id === e.parent_event_id
                    );
                    return {
                      label: `${event ? `${event.event_name} - ` : ""}${
                        e.event_name
                      }`,
                      value: e.id,
                    };
                  })}
                  style={{ flexGrow: 1, width: "100%", textAlign: "left" }}
                  placeholder="Event suchen ..."
                  showSearch
                  optionFilterProp="label"
                  notFoundContent={<>Event wurde nicht gefunden.</>}
                  value={selectedEvent ? selectedEvent.id : undefined}
                  onChange={(eventId) => {
                    setSelectedEvent(subEvents.find((e) => e.id === eventId));
                  }}
                  className="select-event"
                />
                <Input
                  placeholder="Statnummer"
                  style={{ flexShrink: 2 }}
                  onKeyUp={(e) => {
                    if (e.key.toLocaleLowerCase() === "enter") {
                      handleGotoEvent();
                    }
                  }}
                  value={startNo}
                  onChange={(e) => {
                    setStartNo(e.target.value);
                  }}
                  className="startnummer"
                />
                <Button
                  type="primary"
                  disabled={!selectedEvent || !startNo}
                  onClick={handleGotoEvent}
                  className="picthis-button"
                >
                  pic this
                </Button>
              </Row>
            </Card>
            {events &&
              events.map((e) => {
                return <EventCard key={e.id} event={e} />;
              })}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Home;
