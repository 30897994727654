import React from "react";
import { Card, Col, Row } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { Link } from "react-router-dom";

const EventCard = ({ event }) => {
  const dayFrom = dayjs(event.event_start_date).format("DD");
  const dayTo = dayjs(event.event_end_date).format("DD");
  const month = dayjs(event.event_start_date).format("MMM");

  return (
    <Link
      to={
        event.subevents && event.subevents.length > 1
          ? `/event/${event.url_keywords}`
          : `/event/${event.url_keywords}/${event.subevents[0].url_keywords}`
      }
    >
      <Card className="event-card">
        <Row gutter={24}>
          <Col xs={8}>
            <div className="event-image">
              <div className="image-container">
                <img src={event.event_cover_image_url} alt={event.event_name} />
              </div>
              <div className="image-overlay">
                <div className="date">
                  {dayFrom !== dayTo ? `${dayFrom} - ${dayTo}` : dayFrom}
                </div>
                <div className="month">{month.toLocaleUpperCase()}</div>
              </div>
            </div>
          </Col>
          <Col xs={16} className="event-details">
            <div className="event-title">{event.event_name}</div>
            <div className="event-description">{event.event_description}</div>
            <div className="event-location">
              <span className="country-icon">{getUnicodeFlagIcon("AT")}</span>
              {event.event_location}
            </div>
          </Col>
        </Row>
      </Card>
    </Link>
  );
};

EventCard.propTypes = {
  children: PropTypes.any,
  event: PropTypes.object,
};

export default EventCard;
