import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, message, Row, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./photos.less";
import axios from "axios";
import ParticipantPhotoModal from "../ParticipantPhotoModal";
import { SearchOutlined } from "@ant-design/icons";
import WeitereFotosHinzufuegenModal from "./WeitereFotosHinzufuegenModal";

const FotosVonStartnummer = ({
  eventUrlKeywords,
  subEventUrlKeywords,
  event,
  subEvent,
  startNo,
  photoId,
  photoProducts,
}) => {
  const [loading, setLoading] = useState(false);
  const [participant, setParticipant] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [
    weitereFotosHinzufuegenModalVisible,
    setWeitereFotosHinzufuegenModalVisible,
  ] = useState(false);

  const navigate = useNavigate();

  function loadParticipant() {
    setLoading(true);
    axios
      .get(
        `https://api.picthis.one/participants/get/${eventUrlKeywords}/${subEventUrlKeywords}/${startNo}`
      )
      .then(({ data }) => {
        setLoading(false);
        if (data && data.participant) {
          setParticipant(data.participant);
        } else if (data && data.error) {
          message.error("Die Startnummer gibt es nicht.");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function loadPhotos() {
    if (participant) {
      setLoading(true);
      axios
        .get(`https://api.picthis.one/photos/${participant.id}`)
        .then(({ data }) => {
          if (data && data.photos) {
            setPhotos(data.photos);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }

  function hasNextPhoto() {
    if (photoId) {
      const photoIndex = photos.findIndex(
        (p) => Number(p.id) === Number(photoId)
      );

      if (
        photos.length > 0 &&
        photoIndex >= 0 &&
        photoIndex < photos.length - 1
      ) {
        return () => {
          navigate(
            `../event/${eventUrlKeywords}/${subEventUrlKeywords}/${
              participant.start_no
            }/${photos[photoIndex + 1].id}`
          );
        };
      }
    }

    return null;
  }

  function hasPrevPhoto() {
    if (photoId) {
      const photoIndex = photos.findIndex(
        (p) => Number(p.id) === Number(photoId)
      );

      if (photoIndex > 0 && photos.length > 0) {
        return () => {
          navigate(
            `../event/${eventUrlKeywords}/${subEventUrlKeywords}/${
              participant.start_no
            }/${photos[photoIndex - 1].id}`
          );
        };
      }
    }

    return null;
  }

  useEffect(loadParticipant, [
    eventUrlKeywords,
    subEventUrlKeywords,
    event,
    subEvent,
    startNo,
  ]);
  useEffect(loadPhotos, [participant]);

  return (
    <>
      <Spin spinning={loading}>
        {participant && (
          <Row>
            <Col xs={24} md={16}>
              <h1 style={{ marginBottom: 0 }}>
                {participant.first_name} {participant.last_name}
              </h1>
              <h2 style={{ marginTop: 0, fontWeight: "lighter" }}>
                {participant.run_time || "--:--"}
              </h2>
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                ghost
                icon={<SearchOutlined />}
                onClick={() => {
                  setWeitereFotosHinzufuegenModalVisible(true);
                }}
              >
                Du suchst noch Fotos von dir?
              </Button>
            </Col>
          </Row>
        )}

        <div
          className="startnummer-photos-container"
          style={{ marginTop: "32px" }}
        >
          {photos &&
            photos.map((p, index) => (
              <div className="photo" key={p.id}>
                <Link to={`./${p.id}`}>
                  <img
                    src={`https://api.picthis.one/photos/${participant.id}/${p.thumbnail_filename}`}
                    alt={`Foto #${index + 1} von Startnummer ${startNo}`}
                  />
                </Link>
              </div>
            ))}
        </div>
      </Spin>
      {/**
       * ------------------------------
       * Modal für angeklicktes Foto
       */}
      <ParticipantPhotoModal
        visible={photoId != null}
        onClose={() => {
          navigate(
            `/event/${eventUrlKeywords}/${subEventUrlKeywords}/${startNo}`
          );
        }}
        event={event}
        subEvent={subEvent}
        photoId={photoId}
        photoProducts={photoProducts}
        participant={participant}
        hasNextPhoto={hasNextPhoto()}
        hasPrevPhoto={hasPrevPhoto()}
      />
      <WeitereFotosHinzufuegenModal
        visible={weitereFotosHinzufuegenModalVisible}
        onClose={() => {
          setWeitereFotosHinzufuegenModalVisible(false);
        }}
        participant={participant}
        onSuccess={() => {
          setWeitereFotosHinzufuegenModalVisible(false);
          loadPhotos();
        }}
        eventId={subEvent.id}
      />
    </>
  );
};

FotosVonStartnummer.propTypes = {
  children: PropTypes.any,
  eventUrlKeywords: PropTypes.string.isRequired,
  subEventUrlKeywords: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  subEvent: PropTypes.object.isRequired,
  startNo: PropTypes.string.isRequired,
  photoId: PropTypes.string,
  photoProducts: PropTypes.array,
};

export default FotosVonStartnummer;
