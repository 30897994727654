import React, { useEffect, useState } from "react";
import { Button, Card, Input } from "antd";
import PropTypes from "prop-types";
import "./startnummerncard-styles.less";

const StartnummernCard = ({ defaultStartNo, onChange }) => {
  const [startNummer, setStartNummer] = useState(null);

  function submitStartnummer() {
    if (onChange) {
      onChange(startNummer);
    }
  }

  useEffect(() => {
    if (defaultStartNo) {
      setStartNummer(defaultStartNo);
    }
  }, [defaultStartNo]);

  return (
    <Card className="event-startnummern-suche-card">
      <div className="input-wrapper">
        <span>Gib deine Startnummer ein</span>
        <Input
          bordered={false}
          placeholder="0000"
          defaultValue={defaultStartNo}
          value={startNummer}
          onChange={(e) => {
            setStartNummer(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key.toLocaleLowerCase() === "enter") {
              submitStartnummer();
            }
          }}
        />
      </div>
      <div className="submit-button-wrapper">
        <Button
          className="picthis-button"
          type="primary"
          onClick={submitStartnummer}
        >
          pic this
        </Button>
      </div>
    </Card>
  );
};

StartnummernCard.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func,
  defaultStartNo: PropTypes.string,
};

export default StartnummernCard;
