import React from "react";
import PicthisAnimation from ".";
import PropTypes from "prop-types";
import animationData from "../../animations/mail-sent.json";

const MailSentAnimation = ({ style }) => {
  return <PicthisAnimation animationData={animationData} style={style} />;
};

MailSentAnimation.propTypes = {
  style: PropTypes.object,
};

export default MailSentAnimation;
