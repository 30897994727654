import React from "react";
import { Button, Card, Descriptions, Modal } from "antd";
import PropTypes from "prop-types";
import { format } from "currency-formatter";
import "./PhotoProductCardStyles.less";

export default function PhotoProductCard({ photoProduct }) {
  const { package_name, is_single_photo, price, descriptions, vouchers } =
    photoProduct;

  return (
    <Card title={package_name} className="photo-product-card">
      {descriptions && Number(is_single_photo) === 1 && (
        <Descriptions
          labelStyle={{ width: "70%" }}
          column={1}
          colon=""
          contentStyle={{
            justifyContent: "flex-end",
          }}
        >
          {descriptions &&
            descriptions.map((d) => (
              <Descriptions.Item
                key={d.id}
                label={d.product_description}
                contentStyle={{ fontWeight: "bold" }}
              >
                {format(Number(price) / 100, { code: "EUR" })}
              </Descriptions.Item>
            ))}
        </Descriptions>
      )}
      {descriptions && Number(is_single_photo) === 0 && (
        <>
          <Descriptions
            column={1}
            colon=""
            contentStyle={{ justifyContent: "flex-start" }}
          >
            {descriptions &&
              descriptions.map((d) => (
                <Descriptions.Item key={d.id} label={d.product_description} />
              ))}

            {vouchers &&
              Array.isArray(vouchers) &&
              vouchers.length > 0 &&
              vouchers.map((v) => (
                <Descriptions.Item key={v.id} label={v.short_description}>
                  {v.icon_url ? (
                    <Button
                      type="link"
                      onClick={() => {
                        Modal.info({
                          icon: null,
                          title: v.short_description || null,
                          width: "600px",
                          content: (
                            <>
                              {v.long_description && (
                                <p>{v.long_description}</p>
                              )}
                              <img
                                src={v.image_url}
                                alt={v.short_description}
                                style={{
                                  width: "100%",
                                  maxHeight: "400px",
                                  objectFit: "contain",
                                }}
                              />
                            </>
                          ),
                        });
                      }}
                    >
                      <img
                        alt={v.short_description}
                        title={v.short_description}
                        src={v.icon_url}
                        style={{
                          width: "50px",
                          objectFit: "contain",
                        }}
                      />
                    </Button>
                  ) : (
                    <></>
                  )}
                </Descriptions.Item>
              ))}
          </Descriptions>
          <div
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "right",
              marginTop: "24px",
            }}
          >
            {format(Number(price) / 100, { code: "EUR" })}
          </div>
        </>
      )}
    </Card>
  );
}

PhotoProductCard.propTypes = {
  children: PropTypes.any,
  photoProduct: PropTypes.object,
};
