import React, { useState } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Link } from "react-router-dom";
import { Button } from "antd";
import PropTypes from "prop-types";
import "../styles/slider.less";

const HeaderSlider = ({ events }) => {
  const [currentSliderIndex, setCurrentSliderIndex] = useState(0);
  const [currentSlideProgress, setCurrentSlideProgress] = useState(0);

  const gotoSlide = (slideNo) => {
    splide.current.go(slideNo);
  };

  const splide = React.createRef();

  return events && events.length > 0 ? (
    <Splide
      aria-label="Latest events"
      className="splide"
      hasTrack={false}
      options={{
        arrows: false,
        autoplay: true,
        loop: true,
        rewind: true,
        pauseOnHover: false,
        pagination: false,
        drag: false,
        pauseOnFocus: false,
        type: "fade",
        speed: 2000,
      }}
      onMove={(_, newIdx) => {
        setCurrentSliderIndex(newIdx);
      }}
      ref={splide}
      onAutoplayPlaying={(_, rate) => {
        setCurrentSlideProgress(rate);
      }}
    >
      <div className="carousel-overlay">
        {events.map((e, index) => (
          <div
            key={e.id}
            className={`event-details ${
              currentSliderIndex === index ? "active" : ""
            }`}
          >
            <div className="event-title">{e.event_name}</div>
            <div className="event-button">
              <Link to={`/event/${e.url_keywords}`}>Zum Event</Link>
            </div>
          </div>
        ))}

        <div className="pagination">
          {events.map((e, index) => (
            <div
              key={e.id}
              className={`page ${currentSliderIndex === index ? "active" : ""}`}
            >
              <div className="carousel-progress-wrapper">
                <div
                  className="carousel-progress"
                  style={{
                    width:
                      currentSliderIndex === index
                        ? `${currentSlideProgress * 100}%`
                        : 0,
                  }}
                ></div>
              </div>
              <Button
                type="link"
                className="event-title"
                onClick={() => {
                  gotoSlide(index);
                }}
              >
                {e.event_name}
              </Button>
            </div>
          ))}
        </div>
      </div>
      <SplideTrack>
        {events.map((e) => (
          <SplideSlide key={e.id}>
            <div
              style={{
                backgroundImage: `url('${e.event_cover_image_url}')`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
              }}
            ></div>
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  ) : (
    <></>
  );
};

HeaderSlider.propTypes = {
  children: PropTypes.any,
  events: PropTypes.array,
};

export default HeaderSlider;
