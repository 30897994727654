import React from "react";
import ShoppingCart from ".";

export default function ShoppingCartFullpage() {
  return (
    <div className="container">
      <div className="content">
        <ShoppingCart />
      </div>
    </div>
  );
}
