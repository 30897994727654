import React from "react";

export default function Privacy() {
  return (
    <div className="container">
      <div className="content text-left">
        <h1 className="big-heading">Datenschutz</h1>
        <p>
          <i className="text-secondary"> 31.08.2020 </i>
        </p>
        <p>
          Ein vertrauensvoller und sicherer Umgang mit personenbezogenen Daten
          ist uns sehr wichtig. Wir beachten die Vorschriften der
          EU-Datenschutzgrundverordnung (DSGVO) sowie weitere europäischen
          Regelungen und möchten Sie in dieser Datenschutzerklärung ausführlich
          und transparent über die Verarbeitung Ihrer personenbezogenen Daten
          informieren.
        </p>
        <p>
          Personenbezogene Daten sind alle Daten, die auf Sie persönlich
          beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen oder
          Nutzerverhalten. Hinsichtlich der weiteren nachfolgend verwendeten
          Begriffe, wie bspw. „Verantwortlicher“ oder „Auftragsverarbeiter“,
          verweisen wir auf den Definitionskatalog der Begriffsbestimmungen in
          Art. 4 DSGVO.
        </p>

        <h4>Verantwortlicher</h4>
        <p>
          Die Erhebung, Verarbeitung und Nutzung personenbezogener Daten im
          Zusammenhang mit der Nutzung des Internetauftritts www.picthis.one und
          anderer hierin beschriebener Datenverarbeitungsprozesse erfolgt durch
          die
        </p>
        <ul className="list-unstyled">
          <li>DDM Ventures GmbH</li>
          <li>Nobilegasse 20/38</li>
          <li>1150 Wien</li>
          <li>FN 474002 m</li>
          <li>UID: ATU72469606</li>
          <li id="em">
            <a href="mailto:office@picthis.one">office@picthis.one</a>
          </li>
        </ul>

        <h4>Datenschutzbeauftragter</h4>
        <p>
          Sollten Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
          E-Mail oder wenden Sie sich direkt an unseren Datenschutzbeauftragten.
          Diesen erreichen Sie unter den folgenden Kontaktdaten:
          <span id="em2">
            <a href="mailto:office@picthis.one">office@picthis.one</a>
          </span>
        </p>

        <h4>Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten</h4>
        <p>
          Wir erheben, verarbeiten und/oder nutzen personenbezogene Daten nur,
          wenn Sie eingewilligt haben oder dies durch Gesetz erlaubt ist. Dieses
          im Datenschutzrecht geltende „Verbot mit Erlaubnisvorbehalt“ bedeutet,
          dass Verarbeitungen nur auf Grundlage der Einwilligung oder eines
          gesetzlichen Erlaubnistatbestands erfolgen dürfen. Die wichtigsten und
          für uns einschlägigen Erlaubnistatbestände finden sich in Art. 6 Abs.
          1 DSGVO. Diese betreffen insbesondere den Fall,
        </p>
        <ul>
          <li>
            dass eine Einwilligung des Betroffenen vorliegt, vgl. Art. 6 Abs. 1
            lit. a i.V.m Art. 7 DSGVO,
          </li>
          <li>
            dass die Verarbeitung der personenbezogenen Daten zur Erfüllung
            unserer vertraglichen Pflichten erforderlich ist, vgl. Art. 6 Abs. 1
            lit. b DSGVO,
          </li>
          <li>
            oder dass die Verarbeitung aufgrund unserer berechtigten Interessen
            (bspw. Analyse und Fortentwicklung unserer Produkte, Steigerung der
            wirtschaftlichen Effizienz) erfolgt, vgl. Art. 6 Abs. 1 lit. f
            DSGVO.
          </li>
        </ul>

        <h4>
          Art der Daten und Zweckbestimmung der Erhebung, Verarbeitung oder
          Nutzung personenbezogener Daten
        </h4>
        <h5>A.&nbsp;Informatorischen Nutzung</h5>
        <p>
          Bei der bloßen informatorischen Nutzung unserer Webseite, also wenn
          Sie sich nicht registrieren oder uns anderweitig Informationen
          übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr
          Browser an unsere Server übermittelt.
        </p>
        <p>
          Wenn Sie unsere Webseite aufrufen, erheben wir aufgrund unserer
          berechtigten Interessen nach Art. 6 Abs. 1 lit. f DSGVO die folgenden
          Daten, die für uns technisch erforderlich sind, um Ihnen unsere
          Webseite anzuzeigen und die Datensicherheit sowie die Stabilität und
          Sicherheit unserer IT-Systeme zu gewährleisten:
        </p>
        <ul>
          <li>IP-Adresse</li>
          <li>der Domain-Name der Website, von der Sie kamen</li>
          <li>die Websites, die Sie in unserem Angebot besucht haben</li>
          <li>die Namen der abgerufenen Dateien</li>
          <li>Datum und Uhrzeit eines Abrufs</li>
          <li>der Name Ihres Internet Service Providers</li>
          <li>sowie ggf. Betriebssystem und Browserversion Ihres PCs.</li>
        </ul>

        <h5>B.&nbsp;Kontaktaufnahme per E-Mail</h5>
        <p>
          Bei einer Kontaktaufnahme mit uns per E-Mail verarbeiten wir die von
          Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name,
          Telefonnummer und weitere Angaben) um Ihre Fragen zu bearbeiten und zu
          beantworten; Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO.
        </p>

        <h5>C.&nbsp;Online Shop</h5>
        <p>
          Wir verarbeiten Bestandsdaten (z.B., Namen und Adressen sowie
          Kontaktdaten von Nutzern), Vertragsdaten (z.B., in Anspruch genommene
          Leistungen, Namen von Kontaktpersonen, Zahlungsinformationen) zwecks
          Erfüllung unserer vertraglichen Verpflichtungen und Serviceleistungen
          gem. Art. 6 Abs. 1 lit b. DSGVO.
        </p>
        <p>
          Nutzer können optional ein Nutzerkonto anlegen, indem sie insbesondere
          ihre Bestellungen einsehen können. Im Rahmen der Registrierung, werden
          die erforderlichen Pflichtangaben den Nutzern mitgeteilt. Die
          Nutzerkonten sind nicht öffentlich und können von Suchmaschinen nicht
          indexiert werden. Es obliegt den Nutzern, ihre Daten bei erfolgter
          Kündigung vor dem Vertragsende zu sichern. Im Falle der Kündigung des
          Nutzerkontos sind wir berechtigt, sämtliche während der Vertragsdauer
          gespeicherten Daten des Nutzers unwiederbringlich zu löschen.
        </p>
        <p>
          Im Rahmen der Registrierung und erneuter Anmeldungen sowie
          Inanspruchnahme unserer Onlinedienste, speichern wird die IP-Adresse
          und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung
          erfolgt auf Grundlage unserer berechtigten Interessen, als auch der
          Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine
          Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer
          sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht
          hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO.
        </p>
        <p>
          Wir verarbeiten Nutzungsdaten (z.B., die besuchten Webseiten unseres
          Onlineangebotes, Interesse an unseren Produkten) und Inhaltsdaten
          (z.B., Eingaben im Kontaktformular oder Nutzerprofil) für Werbezwecke
          in einem Nutzerprofil, um den Nutzer z.B. Produkthinweise ausgehend
          von ihren bisher in Anspruch genommenen Leistungen einzublenden.
        </p>

        <h5>D.&nbsp;Newsletter</h5>
        <p>
          Wir bieten allen Interessenten die Möglichkeit an, sich für den
          Newsletter anzumelden. Dieser erscheint in unregelmäßigen Abständen
          und beschäftigt sich vor allem mit allem was das Rennherz begehrt!
        </p>
        <p>
          <b>
            Zweck der Datenverarbeitung, Rechtsgrundlage und Inhalt der
            Einwilligung:
          </b>
          &nbsp;Im Rahmen der Anmeldung ist lediglich die Angabe Ihre
          E-Mail-Adresse erforderlich. Wir versenden unseren Newsletter nur auf
          Grundlage der Einwilligung der Empfänger nach Art. 6 Abs. 1 lit. a
          i.V.m Art. 7 DSGVO. Wenn Sie unseren Newsletter abonnieren, erklären
          Sie sich mit dem Empfang von Informationen und werblichen Materialien
          hinsichtlich der Angebote und Aktionen von picthis.one einverstanden.
        </p>

        <p>
          <b>Double Opt-In Verfahren:</b>&nbsp;Im Rahmen der Anmeldung zum
          Newsletter nutzen wir das sog. Double Opt-In Verfahren. Der
          Interessent erhält nach der Anmeldung eine E-Mail mit einem
          Bestätigungs-Link an die angegebene E-Mail Adresse, den er zur
          Bestätigung der Anmeldung zum Newsletter anklicken muss. Wir
          protokollieren die Anmeldungen zum Newsletter, um den Anmeldeprozess
          entsprechend den datenschutzrechtlichen Anforderungen nachweisen zu
          können. In diesem Zusammenhang speichern wir den Zeitpunkt des
          Anmelde- und des Bestätigungszeitpunkts sowie die IP-Adresse.
        </p>

        <p>
          <b>Angaben zum Dienstleister:</b>&nbsp;Der Versand der Newsletter
          erfolgt mittels „MailChimp“, einer Newsletter-Versandplattform des
          US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE
          #5000, Atlanta, GA 30308, USA. Die Datenschutzbestimmungen des
          Versanddienstleisters können Sie hier einsehen:
          https://mailchimp.com/legal/privacy/. The Rocket Science Group LLC
          d/b/a MailChimp ist unter dem Privacy-Shield-Abkommen zertifiziert und
          bietet hierdurch eine Garantie, das europäisches
          Datenschutzniveau&nbsp;einzuhalten&nbsp;(https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&amp;status=Active).
          Des Weiteren kann der Versanddienstleister nach eigenen Informationen
          diese Daten in pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer,
          zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B.
          zur technischen Optimierung des Versandes und der Darstellung der
          Newsletter oder für statistische Zwecke, um zu bestimmen aus welchen
          Ländern die Empfänger kommen, verwenden. Der Versanddienstleister
          nutzt die Daten unserer Newsletter-Empfänger jedoch nicht, um diese
          selbst anzuschreiben oder an Dritte weiterzugeben. Der Einsatz des
          Versanddienstleisters, Durchführung der statistischen Erhebungen und
          Analysen sowie Protokollierung des Anmeldeverfahrens, erfolgen auf
          Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f
          DSGVO. Unser Interesse richtet sich auf den Einsatz eines
          nutzerfreundlichen sowie sicheren Newsletter-Systems, das sowohl
          unseren geschäftlichen Interessen dient, als auch den Erwartungen der
          Nutzer entspricht.
        </p>
        <p>
          <b>Statistische Erhebung und Analysen:</b>&nbsp;Die Newsletter
          enthalten einen sog. „web-beacon“, d.h. eine pixelgroße Datei, die
          beim Öffnen des Newsletters von dem Server des Versanddienstleisters
          abgerufen wird. Im Rahmen dieses Abrufs werden zunächst technische
          Informationen, wie Informationen zum Browser und Ihrem System, als
          auch Ihre IP-Adresse und Zeitpunkt des Abrufs erhoben. Diese
          Informationen werden zur technischen Verbesserung der Services anhand
          der technischen Daten oder der Zielgruppen und ihres Leseverhaltens
          anhand derer Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind)
          oder der Zugriffszeiten genutzt. Zu den statistischen Erhebungen
          gehört ebenfalls die Feststellung, ob die Newsletter geöffnet werden,
          wann sie geöffnet werden und welche Links geklickt werden. Diese
          Informationen können aus technischen Gründen zwar den einzelnen
          Newsletter-Empfängern zugeordnet werden. Es ist jedoch weder unser
          Bestreben, noch das des Versanddienstleisters, einzelne Nutzer zu
          beobachten. Die Auswertungen dienen uns viel mehr dazu, die
          Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte auf sie
          anzupassen oder unterschiedliche Inhalte entsprechend den Interessen
          unserer Nutzer zu versenden.
        </p>
        <p>
          <b>Kündigung/Widerruf:</b>&nbsp;Sie können den Empfang unseres
          Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen widerrufen.
          Damit erlöschen gleichzeitig Ihre Einwilligungen in dessen Versand
          durch den Versanddienstleister und die statistischen Analysen. Ein
          getrennter Widerruf des Versandes durch den Versanddienstleister oder
          die statistische Auswertung ist leider nicht möglich. Einen Link zur
          Kündigung des Newsletters finden Sie am Ende eines jeden Newsletters.
          Wenn die Nutzer sich nur zum Newsletter angemeldet und diese Anmeldung
          gekündigt haben, werden ihre personenbezogenen Daten gelöscht.
        </p>

        <h5>E. Fotografie auf Sportveranstaltungen</h5>
        <p>
          Wenn Sie an einer Veranstaltung teilnehmen, bei der wir als exklusiver
          Fotodienst vom Veranstalter beauftragt wurden, erheben wir ggf.
          Bildmaterial, auf dem Sie persönlich, ggf. mit ihrer Teilnehmernummer
          der Veranstaltung (z.B. Startnummer), zu erkennen sind.
        </p>
        <p>
          Wir nutzen die bei den Sportveranstaltungen erhobenen Daten um unsere
          Verpflichtung als Fotodienstleister aus dem zugrunde liegenden Vertrag
          mit dem Veranstalter zu erfüllen sowie in unserem eigenen
          wirtschaftlichen Interesse, um die Fotos der Veranstaltung an die sich
          interessierenden Teilnehmer über unsere Website www.picthis.one zu
          vermarkten. Wir können festhalten, dass die Teilnehmer der
          Sportveranstaltungen zum einen transparent darüber aufgeklärt werden,
          dass picthis.one als exklusiver Fotodienstleister eingesetzt wird und
          das zum anderen jeder Teilnehmer einer solchen Veranstaltung damit
          rechnen muss, dass der Veranstalter bzw. dessen Kooperationspartner
          Fotos solcher Events als Erinnerungen bzw. Souvenirs vermarktet.
        </p>
        <p>
          Rechtsgrundlage sind unsere vorgenannten berechtigten Interessen nach
          Art. 6 Abs. 1 lit. f DSGVO. Sie können gegen diese Verarbeitung gem.
          Ziffer 12 Widerspruch einlegen. Ihre Daten werden nicht an Dritte
          weitergeben. Zur Erfassung der entsprechenden Fotos setzen wir
          ausschließlich Fotografen ein, die wir datenschutzrechtlich nach der
          EU-Datenschutzgrundverordnung verpflichtet haben.
        </p>

        <h5>F.&nbsp;Google Analytics</h5>
        <p>
          <b>Funktionsweise:Funktionsweise:</b>&nbsp;Diese Website benutzt
          Google Analytics, einen Webanalysedienst der Google Inc. („Google“).
          Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem
          Computer gespeichert werden und die eine Analyse der Benutzung der
          Website durch Sie ermöglichen. Die durch das Cookie erzeugten
          Informationen über Ihre Benutzung dieser Website werden in der Regel
          an einen Server von Google in den USA übertragen und dort gespeichert.
          Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website,
          wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten
          der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
          über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
          Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
          den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
          Website wird Google diese Informationen benutzen, um Ihre Nutzung der
          Website auszuwerten, um Reports über die Website-Aktivitäten
          zusammenzustellen und um weitere mit der Website-Nutzung und der
          Internetnutzung verbundene Dienstleistungen gegenüber dem
          Website-Betreiber zu erbringen. Die im Rahmen von Google Analytics von
          Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
          Google zusammengeführt.&nbsp;Diese Website benutzt Google Analytics,
          einen Webanalysedienst der Google Inc. („Google“). Google Analytics
          verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer
          gespeichert werden und die eine Analyse der Benutzung der Website
          durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen
          über Ihre Benutzung dieser Website werden in der Regel an einen Server
          von Google in den USA übertragen und dort gespeichert. Im Falle der
          Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre
          IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der
          Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
          den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen
          wird die volle IP-Adresse an einen Server von Google in den USA
          übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website
          wird Google diese Informationen benutzen, um Ihre Nutzung der Website
          auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen
          und um weitere mit der Website-Nutzung und der Internetnutzung
          verbundene Dienstleistungen gegenüber dem Website-Betreiber zu
          erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
          übermittelte IP-Adresse wird nicht mit anderen Daten von Google
          zusammengeführt.
        </p>
        <p>
          Sie können die Speicherung der Cookies ablehnen; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können. Sie
          können darüber hinaus die Erfassung der durch das Cookie erzeugten und
          auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem sie das unter dem folgenden Link verfügbare Browser-Plug-in
          herunterladen und installieren:
          http://tools.google.com/dlpage/gaoptout?hl=de.
        </p>

        <p>
          Diese Website verwendet Google Analytics mit der Erweiterung
          „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt
          weiterverarbeitet, eine Personenbeziehbarkeit kann damit
          ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein
          Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die
          personenbezogenen Daten damit umgehend gelöscht.
        </p>

        <p>
          <b>Zweckbestimmung:</b>&nbsp;Wir nutzen Google Analytics, um die
          Nutzung unserer Website analysieren und regelmäßig verbessern zu
          können. Über die gewonnenen Statistiken können wir unser Angebot
          verbessern und für Sie als Nutzer interessanter ausgestalten. Diese
          Website verwendet Google Analytics zudem für eine geräteübergreifende
          Analyse von Besucherströmen, die über eine User-ID durchgeführt wird.
          Für die Ausnahmefälle, in denen personenbezogene Daten in die USA
          übertragen werden, hat sich Google dem EU-US Privacy Shield
          unterworfen, https://www.privacyshield.gov/EU-US-Framework.
          Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1
          S. 1 lit. a DSGVO.
        </p>

        <p>
          <b>Informationen des Drittanbieters:</b>&nbsp;Google Dublin, Google
          Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland
          <br />
          Fax: +353 (1) 436 1001
          <br />
          Nutzerbedingungen: www.google.com/analytics/terms/de.html
          <br />
          Datenschutzerklärung: www.google.de/intl/de/policies/privacy
        </p>

        <p>
          <b>Widerrufsrecht:</b>&nbsp;Die betroffene Person hat das Recht, ihre
          datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen.
          Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der
          aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
          nicht berührt. Die von Ihnen erteilte Einwilligung können Sie über das
          Aufrufen der Cookie Einstellungen auf unserer Webseite widerrufen.
        </p>

        <h4>
          5.&nbsp;Zweckbestimmung der Erhebung, Verarbeitung oder Nutzung
          personenbezogener Daten
        </h4>
        <p>
          Soweit nicht anders angegeben, erheben, verarbeiten oder nutzen wir
          Ihre bereitgestellten personenbezogenen Daten, um unserer Pflichten
          aus den zu Grunde liegenden Verträgen (z.B. Bestellung von Waren oder
          Dienstleistungen, Zusendung des Newsletters, Zugangsermöglichung zu
          verschiedenen Plattformen) zu erfüllen, vgl. Art. 6 Abs. 1 lit. b
          DSGVO.
        </p>

        <p>
          Im Übrigen, erheben, verarbeiten oder nutzen wir die unter Ziffer 3
          lit. a dargestellten personenbezogenen Daten aufgrund unseres
          berechtigten Interesses die Nutzung unserer Webseite zu ermöglichen
          und deren IT-Sicherheit zu gewährleisten, vgl. Art. 6 Abs. 1 lit. f
          DSGVO.
        </p>

        <h4>
          6.&nbsp;Dauer der Speicherung von personenbezogenen Daten bzw.
          Kriterien für die Speicherdauer
        </h4>
        <p>
          Die bei uns gespeicherten Daten werden gelöscht, sobald sie für ihre
          Zweckbestimmung nicht mehr erforderlich sind, oder die Speicherung zur
          Vertragsabwicklung bzw. Durchführung nicht mehr erforderlich sind und
          der Löschung keine berechtigten Interessen unsererseits oder
          gesetzliche Aufbewahrungspflichten entgegenstehen. Sofern die Daten
          der Nutzer nicht gelöscht werden, weil sie für andere und gesetzlich
          zulässige Zwecke erforderlich sind, wird deren Verarbeitung
          eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere
          Zwecke verarbeitet. Das gilt z.B. für Daten der Nutzer, die aus
          handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
        </p>

        <p>
          Nach gesetzlichen Vorgaben erfolgt die Aufbewahrung für 7 Jahre gemäß
          § 132 Abs. 1 BAO (Bücher, Aufzeichnungen, Lageberichte,
          Buchungsbelege, Handels- und Geschäftsbriefe, Für Besteuerung
          relevante Unterlagen, etc.).
        </p>

        <h4>7.&nbsp;Keine unberechtigte Weitergabe an Dritte</h4>
        <p>
          Wir behandeln die von Ihnen übermittelten persönlichen Daten mit
          höchster Sorgfalt. Wir übermitteln die Daten an Dritte ausschließlich
          dann, wenn dies für die Durchführung und Abwicklung eingegangener
          Vertragsverhältnisse notwendig ist, Sie uns hierzu Ihre Einwilligung
          erteilt haben oder wenn die Weitergabe sonst aufgrund einschlägiger
          gesetzlicher Bestimmungen zulässig ist.
        </p>

        <h4>8.&nbsp;Schutz personenbezogener Daten</h4>
        <p>
          Wir sichern durch ein Bündel von technischen und organisatorischen
          Maßnahmen entsprechend des aktuellen Standes der Technik sowohl unsere
          Website wie auch die in unserem Verantwortungsbereich gespeicherten
          Daten gegen Verlust, Zerstörung, unberechtigtem Zugriff, Veränderungen
          oder Veröffentlichung durch unberechtigte Personen.
        </p>

        <p>
          Die Eingabe und Übertragung von personenbezogenen Daten erfolgt
          verschlüsselt nach dem SSL-Verfahren (Secure-Socket-Layer).
        </p>

        <h5>A.&nbsp;Was ist SSL?</h5>
        <p>
          Eine mit SSL verschlüsselte Website überträgt persönliche Daten
          verschlüsselt an den Server, damit es für Dritte unmöglich ist, diese
          abzufangen oder zu lesen. Durch ein Zertifikat wird unsere Identität
          verifiziert. Sie können je nach Browser durch die grüne Adressleiste
          und/oder das Schloss erkennen, dass eine sichere Verbindung besteht.
          Durch einen Klick auf das Schloss oder die grüne Adressleiste können
          Sie unseren Online-Identitätsnachweis lesen.
        </p>

        <h5>B.&nbsp;Was bewirkt SSL?</h5>
        <p>
          Durch die Verschlüsselung der Übertragung können Sie davon ausgehen,
          dass Ihre eingegebenen Daten nur von uns gelesen werden können. Sie
          können durch die grüne Adressleiste erkennen, dass Sie mit unserem
          Server verbunden sind und es sich nicht um die Seite eines
          Fremdanbieters handelt.
        </p>

        <h4>9.&nbsp;Verwendung von Cookies</h4>
        <p>
          Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
          bestimmter Funktionen zu ermöglichen, verwenden wir auf Grundlage
          unserer berechtigten Interessen nach Art. 6 Abs. 1 lit. f DSGVO auf
          verschiedenen Seiten, sogenannte Cookies.
        </p>

        <p>
          Cookies sind Informationen, die von unserem Webserver oder Webservern
          Dritter an die Web-Browser der Nutzer übertragen und dort für einen
          späteren Abruf gespeichert werden. Bei Cookies kann es sich um kleine
          Dateien oder sonstige Arten der Informationsspeicherung handeln.
        </p>

        <p>
          Wir verwenden „Session-Cookies z.B. um die Speicherung Ihres
          Login-Status oder die Warenkorbfunktion und somit die Nutzung unseres
          Onlineangebotes überhaupt ermöglichen zu können. In einem
          Session-Cookie wird eine zufällig erzeugte eindeutige
          Identifikationsnummer abgelegt, eine sogenannte Session-ID. Außerdem
          enthält ein Cookie die Angabe über die E-Mail-Adresse eines Besuchers
          und die Sprache, die er auf unserer Seite ausgewählt hat. Diese
          Cookies können keine anderen Daten speichern.
        </p>

        <p>
          Über den Einsatz von Cookies im Rahmen pseudonymer Reichweitenmessung
          werden die Nutzer im Rahmen dieser Datenschutzerklärung informiert.
          Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
          gespeichert werden, werden sie gebeten die entsprechende Option in den
          Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte
          Cookies können in den Systemeinstellungen des Browsers gelöscht
          werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen
          dieses Onlineangebotes führen.
        </p>

        <p>
          Sie können dem Einsatz von Cookies, die der Reichweitenmessung und
          Werbezwecken dienen, über die Deaktivierungsseite der
          Netzwerkwerbeinitiative (http://optout.networkadvertising.org/) und
          zusätzlich die US-amerikanische Webseite
          (http://www.aboutads.info/choices) oder die europäische Webseite
          (http://www.youronlinechoices.com/uk/your-ad-choices/) widersprechen.
        </p>

        <h4>10.&nbsp;Einbindung von Diensten und Inhalten Dritter</h4>

        <p>
          Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
          berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
          und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art.
          6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von
          Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos
          oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als
          “Inhalte”). Dies setzt immer voraus, dass die Drittanbieter dieser
          Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die
          IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
          IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich.
          Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige
          Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
          verwenden. Drittanbieter können ferner so genannte Pixel-Tags
          (unsichtbare Grafiken, auch als &quot;Web Beacons&quot; bezeichnet)
          für statistische oder Marketingzwecke verwenden. Durch die
          &quot;Pixel-Tags&quot; können Informationen, wie der Besucherverkehr
          auf den Seiten dieser Website ausgewertet werden. Die pseudonymen
          Informationen können ferner in Cookies auf dem Gerät der Nutzer
          gespeichert werden und unter anderem technische Informationen zum
          Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie
          weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als
          auch mit solchen Informationen aus anderen Quellen verbunden werden
          können.
        </p>

        <p>
          Die nachfolgende Darstellung bietet eine Übersicht von Drittanbietern
          sowie ihrer Inhalte, nebst Links zu deren Datenschutzerklärungen,
          welche weitere Hinweise zur Verarbeitung von Daten und, z.T. bereits
          hier genannt, Widerspruchsmöglichkeiten (sog. Opt-Out) enthalten:
        </p>

        <ul>
          <li>
            Falls unsere Kunden die Zahlungsdienste Dritter (z.B. stripe, PayPal
            oder Worldpay) nutzen, gelten die Geschäftsbedingungen und die
            Datenschutzhinweise der jeweiligen Drittanbieter, welche innerhalb
            der jeweiligen Webseiten, bzw. Transaktionsapplikationen abrufbar
            sind.
          </li>
          <li>
            Externe Schriftarten von Google, Inc., https://www.google.com/fonts
            („Google Fonts“). Die Einbindung der Google Fonts erfolgt durch
            einen Serveraufruf bei Google (in der Regel in den USA).
            <br />
            Datenschutzerklärung: policies.google.com/privacy?hl=de/
            <br />
            Opt-Out: www.google.com/settings/ads/
          </li>
          <li>
            Innerhalb unseres Onlineangebotes sind Funktionen des Dienstes
            Instagram eingebunden. Diese Funktionen werden angeboten durch die
            Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA
            integriert. Wenn Sie in Ihrem Instagram – Account eingeloggt sind
            können Sie durch Anklicken des Instagram – Buttons die Inhalte
            unserer Seiten mit Ihrem Instagram – Profil verlinken. Dadurch kann
            Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen.
            Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
            Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung
            durch Instagram erhalten.
            <br />
            <br />
            Datenschutzerklärung: www.instagram.com/about/legal/privacy/
          </li>
          <li>
            Innerhalb unseres Onlineangebotes können Funktionen des Dienstes
            Twitter eingebunden sein. Diese Funktionen werden angeboten durch
            die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA
            94103, USA. Durch das Benutzen von Twitter und der Funktion
            “Re-Tweet” werden die von Ihnen besuchten Webseiten mit Ihrem
            Twitter-Account verknüpft und anderen Nutzern bekannt gegeben. Dabei
            werden auch Daten an Twitter übertragen. Wir weisen darauf hin, dass
            wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
            übermittelten Daten sowie deren Nutzung durch Twitter erhalten.
            Datenschutzerklärung von Twitter unter www.twitter.com/privacy. Ihre
            Datenschutzeinstellungen bei Twitter können Sie in den
            Konto-Einstellungen unter www.twitter.com/account/settings ändern.
          </li>
        </ul>

        <h4>11.&nbsp;Rechte der Betroffenen</h4>
        <p>
          Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
          betreffenden personenbezogenen Daten:
        </p>

        <ul>
          <li>Recht auf Auskunft nach Art. 15 DSGVO,</li>
          <li>
            Recht auf Berichtigung oder Löschung nach Art. 16 DSGVO bzw. Art. 17
            DSGVO,
          </li>
          <li>Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO,</li>
          <li>Recht auf Datenübertragbarkeit nach Art. 20 DSGVO,</li>
          <li>
            Recht auf Widerspruch gegen die Verarbeitung nach Art. 21 DSGVO.
          </li>
        </ul>

        <p>
          Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde
          über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu
          beschweren. Zuständige Behörde ist die Landesbeauftragte für
          Datenschutz und Informationsfreiheit Nordrhein-Westfalen, Postfach 20
          04 44, 40102 Düsseldorf.
        </p>

        <h4>12.&nbsp;Widerspruch oder Widerruf gegen die Verarbeitung</h4>
        <p>
          Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt
          haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf
          beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen
          Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.
        </p>

        <p>
          <b>
            SOWEIT WIR DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN AUF DIE
            INTERESSENABWÄGUNG NACH ART. 6 ABS. 1 LIT. F DSGVO STÜTZEN, KÖNNEN
            SIE WIDERSPRUCH GEGEN DIE VERARBEITUNG EINLEGEN. BEI AUSÜBUNG EINES
            SOLCHEN WIDERSPRUCHS BITTEN WIR UM DARLEGUNG DER GRÜNDE, WESHALB WIR
            IHRE PERSONENBEZOGENEN DATEN NICHT WIE VON UNS DURCHGEFÜHRT
            VERARBEITEN SOLLTEN. IM FALLE IHRES BEGRÜNDETEN WIDERSPRUCHS PRÜFEN
            WIR DIE SACHLAGE UND WERDEN ENTWEDER DIE DATENVERARBEITUNG
            EINSTELLEN BZW. ANPASSEN ODER IHNEN UNSERE ZWINGENDEN SCHUTZWÜRDIGEN
            GRÜNDE AUFZEIGEN, AUFGRUND DERER WIR DIE VERARBEITUNG FORTFÜHREN.
            SIE KÖNNEN DER VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN FÜR ZWECKE
            DER WERBUNG UND DATENANALYSE JEDERZEIT WIDERSPRECHEN. ÜBER IHREN
            WERBEWIDERSPRUCH KÖNNEN SIE UNS UNTER DEN IN ZIFFER 1 AUFGEFÜHRTEN
            KONTAKTDATEN INFORMIEREN.
          </b>
        </p>

        <h4>13.&nbsp;Änderungen der Datenschutzerklärung</h4>
        <p>
          Wir behalten uns vor, die Datenschutzerklärung zu ändern, um sie an
          geänderte Rechtslagen, oder bei Änderungen des Dienstes sowie der
          Datenverarbeitung anzupassen. Dies gilt jedoch nur im Hinblick auf
          Erklärungen zur Datenverarbeitung. Sofern Einwilligungen der Nutzer
          erforderlich sind oder Bestandteile der Datenschutzerklärung
          Regelungen des Vertragsverhältnisses mit den Nutzern enthalten,
          erfolgen die Änderungen nur mit Zustimmung der Nutzer. Die Nutzer
          werden gebeten sich regelmäßig über den Inhalt der
          Datenschutzerklärung zu informieren.
        </p>

        <h4>14.&nbsp;Fragen, Anmerkungen, Hinweise</h4>
        <p>
          Gerne beantworten wir Ihre Fragen zum Thema Datenschutz und freuen uns
          über Kommentare und Hinweise. Schreiben uns hierzu per E-Mail an
          info@sportograf.com oder schriftlich an die folgende Postadresse:
        </p>
        <ul className="list-unstyled">
          <li>DDM Ventures GmbH</li>
          <li>Nobilegasse 20/38</li>
          <li>1150 Wien</li>
          <li>FN 474002 m</li>
          <li>UID: ATU72469606</li>
          <li id="em3">
            <a href="mailto:office@picthis.one">office@picthis.one</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
