import React from "react";

const AboutUs = () => {
  return (
    <div className="container">
      <div className="content">
        <h1 className="big-heading">Über uns</h1>
        <p className="text-left">
          Wir sind Sportler und Veranstalter aus Leidenschaft. Seit mehr als 34
          Jahren veranstalten wir einen der größten Triathlons in Österreich. In
          dieser Zeit haben wir eines immer an oberste Stelle gestellt: das Wohl
          und das Erlebnis für unsere Athleten.
        </p>

        <p className="text-left">
          Als Sportler wissen wir um die Bedeutung von eindrucksvollen,
          einzigartigen Fotos von einem gefinishten Event, nach oft monatelanger
          Vorbereitung.
        </p>

        <h2>Du möchtest deinen Wettkampf in Erinnerung behalten?</h2>
        <p className="text-left">
          Wir bieten dir Fotos, nicht nur von deinem Zieleinlauf, sondern auch
          unvergessliche Momentaufnahmen von deiner gesamten Wettkampfstrecke.
          Du kannst bei uns aus verschiedenen Bearbeitungs-Varianten wählen und
          dir deine Erinnerungsfotos im Package kaufen und sie dann überall,
          auch auf social media, verwenden.{" "}
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
