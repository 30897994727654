import React from "react";
import PicthisAnimation from ".";
import PropTypes from "prop-types";
import animationData from "../../animations/success.json";

const SuccessAnimation = ({ style }) => {
  return <PicthisAnimation animationData={animationData} style={style} />;
};

SuccessAnimation.propTypes = {
  style: PropTypes.object,
};

export default SuccessAnimation;
