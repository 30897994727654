import React from "react";
import PicthisAnimation from ".";
import PropTypes from "prop-types";
import animationData from "../../animations/lock.json";

const LockAnimation = ({ style }) => {
  return <PicthisAnimation animationData={animationData} style={style} />;
};

LockAnimation.propTypes = {
  style: PropTypes.object,
};

export default LockAnimation;
