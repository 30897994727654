type PhotoProduct = {
  subEventId: number;
  startNo: string;
  photoProductId: number;
  isSinglePhoto: boolean;
  photoId: number | null;
  eventName: string;
  packageName: string;
  price: number;
};

type ShoppingCartStructure = {
  products: PhotoProduct[];
};

class ShoppingCart {
  private static instance: ShoppingCart = null;

  private shoppingCart: ShoppingCartStructure;

  private subscriberFunctions: Function[];

  private constructor() {
    // parse shopping cart
    const jsonShoppingCart = localStorage.getItem("picthis_shopping_cart");
    this.subscriberFunctions = [];

    try {
      this.shoppingCart = jsonShoppingCart
        ? JSON.parse(jsonShoppingCart)
        : { products: [] };
    } catch {
      this.clear();
    }
  }

  public static getInstance(): ShoppingCart {
    if (ShoppingCart.instance == null) {
      ShoppingCart.instance = new ShoppingCart();
    }

    return ShoppingCart.instance;
  }

  public addPhotoProductToCart(photoPackage: PhotoProduct): boolean {
    const alreadyAdded =
      this.shoppingCart.products.find(
        (p) =>
          p.startNo === photoPackage.startNo &&
          p.subEventId === photoPackage.subEventId &&
          p.photoProductId === photoPackage.photoProductId &&
          p.photoId === photoPackage.photoId
      ) != null;

    // falls es zu dieser Startnummer schon ein Package gibt, dann dieses zuerst rauslöschen
    if (!alreadyAdded && !photoPackage.isSinglePhoto) {
      this.photoPackageForStartNoAlreadyAdded(photoPackage, true);
    }

    if (!alreadyAdded && this.shoppingCart && this.shoppingCart.products) {
      this.shoppingCart.products.push(photoPackage);
    }

    localStorage.setItem(
      "picthis_shopping_cart",
      JSON.stringify(this.shoppingCart)
    );

    this.informSubscribers();

    return !alreadyAdded;
  }

  public removePhotoProductFromCart(photoPackage: PhotoProduct): boolean {
    console.log("remove photo", photoPackage);
    const alreadyAdded =
      this.shoppingCart.products.find(
        (p) =>
          p.startNo === photoPackage.startNo &&
          p.subEventId === photoPackage.subEventId &&
          p.photoProductId === photoPackage.photoProductId &&
          p.photoId === photoPackage.photoId
      ) != null;

    console.log("already added", alreadyAdded);

    if (alreadyAdded && this.shoppingCart.products) {
      this.shoppingCart.products = this.shoppingCart.products.filter(
        (p) =>
          p.startNo !== photoPackage.startNo ||
          p.subEventId !== photoPackage.subEventId ||
          p.photoProductId !== photoPackage.photoProductId ||
          p.photoId !== photoPackage.photoId
      );
    }

    localStorage.setItem(
      "picthis_shopping_cart",
      JSON.stringify(this.shoppingCart)
    );

    this.informSubscribers();

    return alreadyAdded;
  }

  public productIsInCart(photoPackage: PhotoProduct): boolean {
    return (
      this.shoppingCart.products.findIndex(
        (p) =>
          p.startNo === photoPackage.startNo &&
          p.subEventId === photoPackage.subEventId &&
          p.photoProductId === photoPackage.photoProductId &&
          p.photoId === photoPackage.photoId
      ) >= 0
    );
  }

  public photoPackageForStartNoAlreadyAdded(
    photoPackageToAdd: PhotoProduct,
    removeIfExisting: boolean = false
  ) {
    const { startNo, subEventId } = photoPackageToAdd;

    const existingPackage = this.shoppingCart.products.find(
      (p) =>
        p.startNo === startNo && p.subEventId === subEventId && !p.isSinglePhoto
    );

    if (existingPackage && removeIfExisting) {
      this.removePhotoProductFromCart(existingPackage);
    }

    return existingPackage != null;
  }

  public subscribeToUpdates(subscriberFunc: Function): Function {
    if (!this.subscriberFunctions.find((sf) => sf === subscriberFunc)) {
      this.subscriberFunctions.push(subscriberFunc);
    }

    return () => {
      this.unsubscribeFromUpdates(subscriberFunc);
    };
  }

  private informSubscribers() {
    this.subscriberFunctions.forEach((sf) => sf());
  }

  private unsubscribeFromUpdates(subscriberFunc: Function) {
    this.subscriberFunctions = this.subscriberFunctions.filter(
      (sf) => sf !== subscriberFunc
    );
  }

  public count(): number {
    return this.shoppingCart.products.length;
  }

  public getItems(): PhotoProduct[] {
    return this.shoppingCart.products;
  }

  public clear() {
    this.shoppingCart = { products: [] };
    localStorage.setItem(
      "picthis_shopping_cart",
      JSON.stringify(this.shoppingCart)
    );
    this.informSubscribers();
  }
}

// module.exports = ShoppingCart;
export default ShoppingCart;