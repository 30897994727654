import React from "react";
import { Popover } from "antd";
import PropTypes from "prop-types";
import ShoppingCart from "../components/ShoppingCart";

const ShoppingCartPopover = ({ children }) => {
  return (
    <Popover placement="bottomLeft" content={<ShoppingCart />}>
      {children}
    </Popover>
  );
};

ShoppingCartPopover.propTypes = {
  children: PropTypes.any,
};

export default ShoppingCartPopover;
