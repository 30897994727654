import React from "react";
import "../../styles/footer.less";
import applePayIcon from "../../img/icons/apple-pay.svg";
import googlePayIcon from "../../img/icons/google-pay.svg";
import mastercardIcon from "../../img/icons/mastercard.svg";
import visaIcon from "../../img/icons/visa.svg";
import epsIcon from "../../img/icons/eps.png";
import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <Row gutter={[24, 12]}>
        <Col xs={24} md={8} style={{ display: "flex", columnGap: "8px" }}>
          <img
            src={applePayIcon}
            height="40"
            title="Apple Pay"
            alt="Apple Pay"
            className="mr-2"
          />
          <img
            src={googlePayIcon}
            height="40"
            title="Google Pay"
            alt="Google Pay"
            className="mr-2"
          />
          <img
            src={mastercardIcon}
            height="40"
            title="Mastercard"
            alt="Mastercard"
            className="mr-2"
          />
          <img
            src={visaIcon}
            height="40"
            title="Visa"
            alt="Visa"
            className="mr-2"
          />
          <img
            src={epsIcon}
            height="40"
            title="EPS Sofortüberweisung"
            alt="EPS Sofortüberweisung"
            className="mr-2"
          />
        </Col>
        <Col xs={24} md={16}>
          <Row gutter={[24, 12]}>
            <Col xs={24} md={8}>
              <ul className="list-unstyled">
                <li>
                  <NavLink to="/">Start</NavLink>
                </li>
                <li>
                  <NavLink to="/faq">FAQ</NavLink>
                </li>
                <li>
                  <NavLink to="/about-us">Über uns</NavLink>
                </li>
              </ul>
            </Col>
            <Col xs={24} md={8}>
              <ul className="list-unstyled">
                <li>
                  <NavLink to="/contact">Kontakt</NavLink>
                </li>
                <li>
                  <NavLink to="/imprint">Impressum</NavLink>
                </li>
                <li>
                  <NavLink to="/privacy">Datenschutz</NavLink>
                </li>
              </ul>
            </Col>
            <Col xs={24} md={8}>
              <ul className="list-unstyled">
                <li>
                  <a href="https://www.facebook.com/Austria.Triathlon.Podersdorf/">
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/austria_triathlon/">
                    Instagram
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
