import React from "react";

export default function Imprint() {
  return (
    <div className="container">
      <div className="content text-left">
        <h2>Impressum</h2>
        <h3 className="big-heading">picthis.one</h3>
        <ul className="list-unstyled">
          <li>DDM Ventures GmbH</li>
          <li>Frauenkirchnerstrasse 9</li>
          <li>7141 Podersdorf</li>
          <li>FN 474002 m</li>
          <li>UID: ATU72469606</li>
          <li id="em">
            <a href="mailto:office@picthis.one">office@picthis.one</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
