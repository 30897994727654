import React from "react";

export default function FAQ() {
  return (
    <div className="container">
      <div className="content text-left">
        <h1 className="big-heading">Frequently Asked Questions</h1>
        <h3>Was ist Pic-this.one?</h3>
        <p>
          Auf unserer Seite verkaufen wir Fotos von Teilnehmerinnen und
          Teilnehmern an ausgewählten Veranstaltungen, damit Sie bleibende
          Erinnerungen haben. Wir bieten Pakete zu einem fairen Preis an, welche
          alle Fotos einer Startnummer plus einigen Impressionsfotos beinhalten.
          Die Fotos werden digital zur Verfügung gestellt.
        </p>
        <h3>Wo finde ich meine Fotos?</h3>
        <p>
          Auf unserer Seite kann je Event zielgerichtet nach der gewünschten
          Startnummer gesucht werden.
        </p>
        <h3>Was ist eine Foto-Flat?</h3>
        <p>
          Statt vielen Einzelfotos kannst Du mit unserer Foto-Flat ein gesamtes
          Paket an Fotos zu einem Fixpreis erwerben. Es beinhaltet alle Fotos,
          auf denen Du mit deiner Startnummer zu sehen bist, plus einigen
          Impressionsfotos von der Veranstaltung. Du erhältst die Fotos digital
          und kannst sie daher so oft du möchtest ausdrucken und versenden.
        </p>
        <h3>Wie kann ich meine Fotos bestellen/herunterladen?</h3>
        <p>
          Um den Bestellvorgang zu starten musst du zuerst auf unsere Website
          www.pichthis.one gehen und dort die Veranstaltung auswählen, an der du
          teilgenommen hast. Gib deine Startnummer in die Suchfunktion ein und
          schon werden dir alle Deine Fotos angezeigt, auf denen Deine
          Startnummer erkannt wurde. Danach musst Du nur noch auf den
          Bestell-Button klicken und kannst dann in wenigen Schritte deine
          Bestellung abschließen.
        </p>
        <h3>Kann ich auch Einzelfotos bestellen?</h3>
        <p>
          Du kannst natürlich auch Einzelfotos bestellen. Einfach das gewünschte
          Foto anklicken und den Bestellvorgang durchlaufen.
        </p>
        <h3>Kann ich die Fotos auch in einer gedruckten Version kaufen?</h3>
        <p>
          Leider ist das nicht möglich. Wir bieten nur digitale Fotos an welche
          als jpg-Datei versendet werden. Diese Foto-Datei kannst du auf jedem
          Computer öffnen und auch noch im Nachhinein beliebig bearbeiten,
          zuschneiden oder sogar vergrößern.
        </p>
        <h3>Welche Auflösung haben die digitalen Fotos?</h3>
        <p>
          Jedes Foto wird in einer Auflösung von 4016 x 6016 Pixel geschossen,
          das sind ca. 24 Megapixel.
        </p>
        <h3>Kann ich die Fotos auf einer Foto-DVD bestellen?</h3>
        <p>Nein, wir bieten keine Foto-DVDs an.</p>
        <h3>Warum finde ich keine Fotos von mir?</h3>
        <p>
          Kontrolliere nochmals ob Du bei der richtigen Veranstaltung gesucht
          hast und ob deine Startnummer stimmt. Die Startnummer kannst Du auf
          der Hompage der Veranstaltung in den Ergebnislisten überprüfen. Falls
          dir noch immer keine Fotos angezeigt werden liegt es daran, dass Deine
          Startnummer nicht gelesen werden konnte (verdreckt, umgeklappt,
          abgerissen, nicht getragen)
        </p>
        <h3>Wie finde und markiere ich weitere Fotos von mir?</h3>
        <p>
          Nicht immer können alle Fotos eindeutig zugeordnet werden, weshalb wir
          Dir die Möglichkeit bieten einzelne Fotos manuell nochmals von uns
          überprüfen zu lassen. Sende uns einfach dazu die Fotonummer und deine
          Startnummer an: office@picthis.one. Wir werden dich benachrichtigen,
          sobald die Fotos freigegeben wurden. Wenn du auf den Bildern jedoch
          nicht zu sehen bist wird die Freigabe abgelehnt, da unsere Foto-Flat
          startnummernbezogen ist.
        </p>
        <h3>
          Kann ich Fotos von anderen Personen zu meiner Foto-Flat hinzufügen?
        </h3>
        <p>
          Nein, die Foto-Flat ist startnummernbezogen und es können somit keine
          Fotos anderer Personen zu deinem Paket hinzugefügt werden!
        </p>
        <h3>
          Was soll ich tun, wenn fremde Personen in meiner Foto-Flat sind?
        </h3>
        <p>
          Hin und wieder kann es passieren, dass fremde Personen in deiner
          Foto-Flat auftauchen. Der Grund dafür ist, dass unsere Software nicht
          immer alle Startnummern fehlerlos zuteilen kann durch verschmutzte,
          kleine oder umgeklappte Startnummern. Falls fremde Personen in deiner
          Foto-Flat auftauchen sollten lösche einfach die Fotos nach dem
          Download.
        </p>
        <h3>Wann wird meine Bestellung fertig sein?</h3>
        <p>
          Die Verarbeitungsdauer der Fotos ist pro Event unterschiedlich, da es
          sehr auf die Anzahl der geschossenen Fotos ankommt. Im Normalfall
          sollte man die Fotos aber spätestens innerhalb von 24 bis 48 Stunden
          nach dem Event bekommen.
        </p>
        <h3>
          Ich habe meine Bestellung vor langer Zeit abgegeben und nie etwas
          erhalten.
        </h3>
        <p>
          Um deine Bestellung ausfindig zu machen würden wir Dich bitten, dass
          du dich an office@picthis.one wendest. Damit wir Dir helfen können,
          benötigen wir deine Bestellnummer, den Veranstaltungsnamen, das Datum
          der Bestellung, die Zahlungsart, die verwendete E-Mail-Adresse und
          ihre Startnummer. Wir werden versuchen Deine Bestellung ausfindig zu
          machen.
        </p>
        <h3>
          Ich habe meine Bestellung aufgegeben und keine Bestellbestätigung per
          Mail erhalten.
        </h3>
        <p>
          Falls du nie eine Bestätigungsmail erhalten haben solltest, könnte es
          daran liegen, dass du bei der Angabe deiner E-Mail-Adresse einen
          Fehler gemacht hast. Wende dich bitte mit deinem Anliegen an unseren
          Kundenservice office@picthis.one, wir werden versuchen dein Problem so
          schnell wie möglich zu lösen!
        </p>
        <h3>Welche Zahlungsmöglichkeiten habe ich?</h3>
        <p>
          Apple Pay, Google Pay, Kreditkarte (Visa und MasterCard),
          Sofortüberweisung
        </p>
        <h3>Rückerstattung/Umtausch/falsche Startnummer bestellt.</h3>
        <p>
          Eine Rückerstattung bzw. ein Umtausch sind für digitale Produkte
          leider nicht möglich. Bitte überprüfe vor Deinem Bestellvorgang genau
          ob die richtigen Bilder ausgewählt wurden.
        </p>
      </div>
    </div>
  );
}
