import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Slider,
  Spin,
} from "antd";
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import axios from "axios";

const WeitereFotosHinzufuegenModal = ({
  visible,
  onClose,
  eventId,
  participant,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [uhrzeit, setUhrzeit] = useState(null);
  const [manualUhrzeit, setManualUhrzeit] = useState([]);
  const [firstFotoUhrzeit, setFirstFotoUhrzeit] = useState([null]);
  const [lastFotoUhrzeit, setLastFotoUhrzeit] = useState([null]);
  const [error, setError] = useState(null);

  dayjs.extend(CustomParseFormat);

  useEffect(() => {
    setUhrzeit(firstFotoUhrzeit);
    setManualUhrzeit(dayjs.unix(firstFotoUhrzeit).format("HH:mm:ss"));
  }, [firstFotoUhrzeit, lastFotoUhrzeit]);

  useEffect(() => {
    if (uhrzeit) {
      setManualUhrzeit(dayjs.unix(uhrzeit).format("HH:mm:ss"));
    }
  }, [uhrzeit]);

  // uhrzeiten von erstem/letztem fotoladen
  useEffect(() => {
    axios
      .get(
        `https://api.picthis.one/photos/event/${eventId}/timestampOfFirstAndLastPhoto`
      )
      .then((data) => data.data)
      .then((data) => {
        if (data.minTime && data.maxTime) {
          setFirstFotoUhrzeit(dayjs(data.minTime, "HH:mm:ss").unix());
          setLastFotoUhrzeit(dayjs(data.maxTime, "HH:mm:ss").unix());
        } else {
          setError(data.error || "Fehler beim Laden der notwendigen Daten.");
        }
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    loadPhotos();
  }, [uhrzeit]);

  function loadPhotos() {
    axios
      .get(
        `https://api.picthis.one/photos/event/${eventId}/photosAtTimestamp/${uhrzeit}`
      )
      .then((data) => data.data)
      .then((data) => {
        if (data.photoUrls) {
          setPhotos(data.photoUrls);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("err", err);
      });
  }

  function addPhotosToParticipant() {
    setLoading(true);
    axios
      .post(
        `https://api.picthis.one/participants/addPhotosToParticipant/${participant.id}`,
        {
          photoIds: selectedPhotos.map((p) => p.id),
        }
      )
      .then((data) => data.data)
      .then((data) => {
        const { success, error, message: m } = data;
        if (success) {
          message.success(m);
          onSuccess();
        } else {
          message.error(error);
        }
        setLoading(false);
      })
      .catch(console.log);
  }

  /* useEffect(() => {
    if (manualUhrzeit) {
      if (dayjs(manualUhrzeit, "HH:mm:ss").isValid()) {
        setUhrzeit(dayjs(manualUhrzeit, "HH:mm:ss").unix());
      } else {
        console.log("not valid");
      }
    }
  }, [manualUhrzeit]); */

  return (
    <Modal
      visible={visible}
      title="Weitere Fotos suchen und hinzufügen"
      onCancel={onClose}
      footer={null}
      width={800}
    >
      {error && (
        <Alert
          type="error"
          showIcon
          message={error}
          style={{ marginBottom: "24px" }}
        />
      )}
      {!error && (
        <>
          <div style={{ fontSize: "0.8rem", color: "#666666", margin: "6px" }}>
            Gib hier die Uhrzeit ein, zu der du Fotos suchen möchtest.
          </div>
          <Row
            align="middle"
            style={{ columnGap: "12px", margin: "0 0 32px 0" }}
          >
            <Input
              value={manualUhrzeit}
              onChange={(e) => {
                setManualUhrzeit(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key.toLocaleLowerCase() === "enter") {
                  if (dayjs(manualUhrzeit, "HH:mm:ss").isValid()) {
                    console.log(
                      "setUhrzeit",
                      dayjs(manualUhrzeit, "HH:mm:ss").format("HH:mm:ss")
                    );
                    setUhrzeit(dayjs(manualUhrzeit, "HH:mm:ss").unix());
                  } else {
                    console.log("not valid");
                  }
                }
              }}
              placeholder="HH:MM:SS"
              style={{ width: "100px" }}
            />
            <Slider
              min={firstFotoUhrzeit || 0}
              max={lastFotoUhrzeit || 0}
              onAfterChange={(val) => setUhrzeit(val)}
              tipFormatter={(val) => dayjs.unix(val).format("HH:mm:ss")}
              style={{ flexGrow: 1 }}
            />
          </Row>
          <Spin spinning={loading}>
            {photos.length > 0 && (
              <Row gutter={[24, 24]}>
                {photos.map((p) => (
                  <Col key={p.id} xs={24} sm={12} lg={8}>
                    <div
                      style={{
                        display: "block",
                        height: "180px",
                        width: "100%",
                      }}
                      onClick={() => {
                        if (
                          selectedPhotos.findIndex((sp) => sp.id === p.id) >= 0
                        )
                          setSelectedPhotos(
                            selectedPhotos.filter((sp) => sp.id !== p.id)
                          );
                        else if (selectedPhotos.length < 8) {
                          setSelectedPhotos([...selectedPhotos, p]);
                        } else {
                          message.warn(
                            "Du kannst nur maximal 8 Fotos auswählen und hinzufügen."
                          );
                        }
                      }}
                    >
                      <img
                        src={p.url}
                        title={p}
                        alt={p}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          border:
                            selectedPhotos.findIndex((sp) => sp.id === p.id) >=
                            0
                              ? "2px solid coral"
                              : "2px solid white",
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            )}
          </Spin>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "24px 0",
            }}
          >
            <Button
              type="primary"
              icon={<CheckCircleFilled />}
              disabled={selectedPhotos.length === 0}
              onClick={addPhotosToParticipant}
              loading={loading}
            >
              Ausgewählte Fotos speichern
            </Button>
          </div>
          <div style={{ marginTop: "24px" }}>
            <span style={{ fontSize: "0.8rem", color: "#666666" }}>
              <ExclamationCircleOutlined /> Du kannst maximal 8 weitere Fotos
              hinzufügen.
            </span>
          </div>
        </>
      )}
    </Modal>
  );
};

WeitereFotosHinzufuegenModal.propTypes = {
  children: PropTypes.any,
  visible: PropTypes.bool,
  eventId: PropTypes.string,
  participant: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default WeitereFotosHinzufuegenModal;
