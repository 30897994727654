import React from "react";
import { Button, Card, Checkbox, Descriptions, message } from "antd";
import PropTypes from "prop-types";
import currencyFormatter from "currency-formatter";
import { DeleteFilled } from "@ant-design/icons";

const SinglePhotosCard = ({
  photoId,
  participant,
  products,
  selectedPhotoProducts,
  productsInShoppingCart,
  onSelect,
  onDeselect,
  onDeleteFromCart,
}) => {
  function productIsSelected(productId) {
    return selectedPhotoProducts.find((p) => p.id === productId) != null;
  }

  function productIsInShoppingCart(productId) {
    return productsInShoppingCart.find((p) => p.id === productId);
  }

  function removeProductFromShoppingCart(productId) {
    const product = productsInShoppingCart.find((p) => p.id === productId);

    if (product) {
      product.removeFromCart();
      onDeleteFromCart();

      message.success("Aus dem Warenkorb entfernt.");
    }
  }

  return (
    <Card className="single-photos-card" title="Dieses Foto">
      <Descriptions
        column={1}
        labelStyle={{ width: "80%", alignItems: "center" }}
        contentStyle={{
          justifyContent: "flex-end",
          fontWeight: "bold",
          alignItems: "center",
        }}
        colon={false}
      >
        {products &&
          products.map((p) => (
            <Descriptions.Item
              key={p.id}
              label={
                productIsInShoppingCart(p.id) ? (
                  <>
                    <Button
                      icon={<DeleteFilled />}
                      type="link"
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "auto",
                        height: "auto",
                        marginRight: "6px",
                      }}
                      onClick={() => {
                        removeProductFromShoppingCart(p.id);
                      }}
                    />{" "}
                    {p.package_name}
                  </>
                ) : (
                  <Checkbox
                    value={p.id}
                    checked={productIsSelected(p.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onSelect(p.id);
                      } else {
                        onDeselect(p.id);
                      }
                    }}
                  >
                    {p.package_name}
                    {p.package_name === "Foto mit Nettozeit" && (
                      <Button
                        type="link"
                        target="_blank"
                        href={`https://api.picthis.one/products/preview/nettozeit/${participant.bewerb_id}/photo/${photoId}`}
                      >
                        Vorschau
                      </Button>
                    )}
                    {p.package_name === "Urkunde mit Foto" && (
                      <Button
                        type="link"
                        target="_blank"
                        href={`https://api.picthis.one/products/preview/urkunde/${participant.bewerb_id}/photo/${photoId}`}
                      >
                        Vorschau
                      </Button>
                    )}
                  </Checkbox>
                )
              }
            >
              {currencyFormatter.format(Number(p.price) / 100, { code: "EUR" })}
            </Descriptions.Item>
          ))}
      </Descriptions>
    </Card>
  );
};

SinglePhotosCard.propTypes = {
  children: PropTypes.any,
  products: PropTypes.array,
  selectedPhotoProducts: PropTypes.array,
  productsInShoppingCart: PropTypes.array,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  onDeleteFromCart: PropTypes.func,
  photoId: PropTypes.string,
  participant: PropTypes.object,
};

export default SinglePhotosCard;
