import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Badge, Button } from "antd";
import {
  CloseOutlined,
  MenuOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import picthisLogo from "../../img/picthis-logo.svg";
import ShoppingCart from "../../shoppingCart/shoppingCart.ts";
import ShoppingCartPopover from "../../shoppingCart/ShoppingCartPopover";

const Navigation = (props) => {
  const { dark } = props;
  const [navigationVisible, setNavigationVisible] = useState(false);
  const [shoppingCartCount, setShoppingCartCount] = useState(
    ShoppingCart.getInstance().count()
  );

  function updateShoppingCart() {
    setShoppingCartCount(ShoppingCart.getInstance().count());
  }

  useEffect(() => {
    return ShoppingCart.getInstance().subscribeToUpdates(updateShoppingCart);
  }, []);

  useEffect(updateShoppingCart, []);

  return (
    <header className={dark ? "dark" : ""}>
      <a href="https://picthis.one">
        <img
          src={picthisLogo}
          title="picthis.one Logo"
          alt="picthis.one Logo"
          className="logo"
        />
      </a>
      <div className={`navigation ${navigationVisible ? "visible" : ""}`}>
        <div className="navigation-item">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Start
          </NavLink>
        </div>
        <div className="navigation-item">
          <NavLink
            to="/about-us"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Über uns
          </NavLink>
        </div>
        <div className="navigation-item">
          <NavLink
            to="/contact"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Kontakt
          </NavLink>
        </div>
      </div>
      <div className="action-buttons">
        <div className="action-item shopping-cart">
          <ShoppingCartPopover>
            <Badge size="small" count={shoppingCartCount} showZero>
              <NavLink
                to="/shopping-cart"
                style={({ isActive }) =>
                  isActive ? { color: "#ff3451" } : undefined
                }
              >
                <ShoppingOutlined />
              </NavLink>
            </Badge>
          </ShoppingCartPopover>
        </div>
        <div
          className="action-item mobile-navigation-button"
          id="mobile-navigation-menu-btn"
        >
          <Button
            type="link"
            onClick={() => {
              setNavigationVisible(!navigationVisible);
            }}
            style={{ margin: 0, padding: 0 }}
          >
            {navigationVisible ? (
              <CloseOutlined style={{ color: "white", fontSize: "1.5em" }} />
            ) : (
              <MenuOutlined style={{ color: "white", fontSize: "1.5em" }} />
            )}
          </Button>
        </div>
      </div>
    </header>
  );
};

Navigation.propTypes = {
  children: PropTypes.any,
  dark: PropTypes.bool,
};

export default Navigation;
