import { message, Progress, Result } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShoppingCart from "../../shoppingCart/shoppingCart.ts";
import SuccessAnimation from "../PicthisAnimation/SuccessAnimation";

const PaymentSuccess = () => {
  const timeout = 6;
  const [timeLeft, setTimeLeft] = useState(timeout);

  const navigate = useNavigate();

  setInterval(() => {
    setTimeLeft(timeLeft - 1);
    if (timeLeft === 0) {
      navigate("/");
    }
  }, 1000);

  // warenkorb leeren
  useEffect(() => {
    ShoppingCart.getInstance().clear();
    message.success("Warenkorb wurde geleert.");
  }, []);

  return (
    <div className="container">
      <div className="content">
        <Result
          icon={
            <SuccessAnimation style={{ maxWidth: "300px", height: "auto" }} />
          }
          title={<>Danke für deinen Einkauf!</>}
          subTitle={<>Du bekommst deine Fotos bald per E-Mail zugeschickt.</>}
        />
        <small style={{ color: "#666666" }}>
          Du wirst in wenigen Sekunden automatisch weitergeleitet ...
        </small>
        <Progress
          type="circle"
          percent={(timeLeft / timeout) * 100}
          showInfo={false}
          width={24}
          status="active"
          style={{ marginLeft: "24px" }}
        />
      </div>
    </div>
  );
};

export default PaymentSuccess;
