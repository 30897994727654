import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import EventMitSubevents from "./EventMitSubevents";
import "./styles.less";

const Event = () => {
  const { eventUrlKeywords } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [subevents, setSubevents] = useState([]);
  const navigate = useNavigate();

  function loadEventData() {
    axios
      .get(`https://api.picthis.one/events/${eventUrlKeywords}`)
      .then(({ data }) => {
        if (data && data.event) {
          const { event, subevents } = data;
          setEvent(event);
          setLoading(false);

          if (subevents) {
            setSubevents(subevents);

            console.log("subevents", subevents);
            if (subevents.length === 1) {
              navigate(
                `/event/${event.url_keywords}/${subevents[0].url_keywords}`
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  }

  useEffect(loadEventData, [eventUrlKeywords]);

  return (
    <>
      <Spin spinning={loading} size="large" />
      {event && subevents && subevents.length > 1 && (
        <EventMitSubevents event={event} subevents={subevents} />
      )}
    </>
  );
};

export default Event;
